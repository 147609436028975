import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useCart } from '../../../components/CartContext';
import { useNavigate } from 'react-router-dom';

// Styled Components for layout and design
const CheckoutContainer = styled.div`
    display: flex;
    height: 100vh;
    color: white;
`;

const LeftSection = styled.div`
    background-color: #00001a;
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const RightSection = styled.div`
    background-color: #ffff99;
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
`;

// Dynamic container with transition effect for M-Pesa content
const MpesaContent = styled.div`
	background-color: #f44336;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    max-height: ${({ isOpen }) => (isOpen ? '300px' : '0')}; /* Adjust for animated height */
`;

const SectionTitle = styled.h2`
    margin-bottom: 20px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
`;

const CartItemsContainer = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    color: black;
    overflow-y: auto;
    max-height: 300px;
    margin-bottom: 20px;
`;

const CartItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
`;

const MpesaCheckout = styled.button`
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        background-color: #d32f2f;
    }
`;

// Keyframes for loading spinner animation
const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

// Styled spinner component
const Spinner = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: ${spin} 1s linear infinite;
    margin: 10px auto;
`;

// New submit button for Mpesa
const MpesaSubmitButton = styled.button`
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background-color: #fff;
    color: #f44336;
    border: 1px solid #f44336;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
        background-color: #f4433650; /* 50% opacity red */
        color: white;
    }
`;

const TotalAmount = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
    text-align: right;
    margin-top: 20px;
`;

const ItemImage = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
`;

const ItemDetails = styled.div`
    flex: 1;
`;

const ItemName = styled.p`
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
`;

const ItemSize = styled.p`
    margin: 5px 0;
    font-size: 0.9rem;
    color: gray;
`;

const Checkout = () => {
	const { cart } = useCart();
	const [isMpesaOpen, setIsMpesaOpen] = useState(false);
	const [msisdn, setMsisdn] = useState('');
	const [fullname, setFullname] = useState('');
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [customer, setCustomer] = useState('');
	const [order, setOrder] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isCustomerLoading, setIsCustomerLoading] = useState(false);
	const [responseMessage, setResponseMessage] = useState('');
	const [ajaxResponseMessage, setAjaxResponseMessage] = useState('');
	const [customerRegistrationResponseMessage, setCustomerRegistrationResponseMessage] = useState('');
	const total = cart.reduce((acc, item) => acc + Number(item.price), 0).toFixed(2);
	// Location
	const [countryList, setCountryList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
	//var csrftoken = getCookie('csrftoken');
	
	useEffect(() => {
        listCountries();
    }, []);

	const openMpesaCheckout = () => setIsMpesaOpen((prev) => !prev);

	// Function to construct the URL
	const constructURL = (path) => {
		var location = window.location.origin;
		return location + path;
		//return `${process.env.REACT_APP_API_BASE_URL}${path}`;
	};

	// Function to get CSRF token from cookies
	const getCookie = (name) => {
		let cookieValue = null;
		if (document.cookie && document.cookie !== '') {
			const cookies = document.cookie.split(';');
			cookies.forEach((cookie) => {
				const cookieTrimmed = cookie.trim();
				if (cookieTrimmed.startsWith(`${name}=`)) {
					cookieValue = decodeURIComponent(cookieTrimmed.substring(name.length + 1));
				}
			});
		}
		return cookieValue;
	};

	const postMpesaPayment = () => {
		setIsLoading(true);
		const url = constructURL("/api/mpesa-payment/");
		const csrftoken = getCookie('csrftoken');

		const data = { msisdn, total, customer, cart }

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrftoken,
			},
			body: JSON.stringify(data),
		})
			.then(response => {
				if (!response.ok) throw new Error("Network response was not ok");
				return response.json();
			})
			.then(data => {
				console.log(data);  // Check data format

				setResponseMessage(JSON.stringify(data));
				console.log(JSON.stringify(data));
				setIsLoading(false);  // Stop loading when data is received
			})
			.catch(error => {
				// Attempt to extract JSON response if available
				let errorMessage = "Error processing M-Pesa payment:";
				try {
					const errorJson = error.message;
					errorMessage += " " + JSON.stringify(errorJson);
				} catch {
					errorMessage += " " + error.message;  // Fallback if error is not JSON
				}

				console.error(errorMessage);
				setResponseMessage(errorMessage);
				setIsLoading(false);
			});
	};

	const postRequest = (endpoint, data,) => {
		setIsCustomerLoading(true);
		var url = constructURL(endpoint);
		const csrftoken = getCookie('csrftoken');

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrftoken,
			},
			body: JSON.stringify(data),
		})
			.then(response => {
				if (!response.ok) throw new Error("Network response was not ok");
				return response.json();
			})
			.then(data => {
				setCustomerRegistrationResponseMessage("Welcome onboard.");
				setCustomer(data);
				setAjaxResponseMessage(data);
				setIsCustomerLoading(false);  // Stop loading when data is received
			})
			.catch(error => {
				// Attempt to extract JSON response if available
				let errorMessage = "Error processing customer information:";
				try {
					const errorJson = error.message;
					errorMessage += " " + JSON.stringify(errorJson);
				} catch {
					errorMessage += " " + error.message;  // Fallback if error is not JSON
				}

				console.error(errorMessage);
				setCustomerRegistrationResponseMessage(errorMessage);
				setIsCustomerLoading(false);
			});
	};

	const listCountries = () => {
        fetch(constructURL('/api/list-countries'))
            .then(response => response.json())
            .then(data => setCountryList(data))
            .catch(error => console.error('Error fetching countries:', error));
    };

	const listStates = (country_id) => {
        fetch(constructURL('/api/list-states/' + country_id))
            .then(response => response.json())
            .then(data => setStateList(data))
            .catch(error => console.error('Error fetching states:', error));
    };

	const listCities = (state_id) => {
        fetch(constructURL('/api/list-cities/' + state_id))
            .then(response => response.json())
            .then(data => setCityList(data))
            .catch(error => console.error('Error fetching cities:', error));
    };

	const registerCustomer = () => {
		var data = { "first_name": fullname, "last_name": "", "email": email, "phone": phoneNumber, "status": '' }
		postRequest("/api/create-customer/", data);
	};

	return (
		<CheckoutContainer>
			{/* Left Section for Customer Details */}
			<LeftSection>
				<SectionTitle>Delivery Information</SectionTitle>
				<form onSubmit={registerCustomer} enctype="multipart/form-data">
					<Input type="text" name='fullname' value={fullname} onChange={(e) => setFullname(e.target.value)} class="form-control"
						placeholder="Full name" required />
					<Input type="text" name='email' value={email} onChange={(e) => setEmail(e.target.value)} class="form-control"
						placeholder="email" required />
					<Input type="text" name='phoneNumber' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} class="form-control"
						placeholder="Phone number" required />

					<Select name="country" class="form-control mb-3" onChange={(e) => listStates(e.target.value)}
						aria-label="Default select example" id="country">
						<option disabled selected>Select country</option>
						{countryList.map(function (country, index) {
							return (
								<option value={country.id}>{country.name} {country.flag}</option>
							)
						})}
					</Select>
					<Select name="state" class="form-control mb-3" onChange={(e) => listCities(e.target.value)}
						aria-label="Default select example" id="state">
						<option disabled selected>Select state</option>
						{stateList.map(function (state, index) {
							return (
								<option value={state.id}>{state.name}</option>
							)
						})}
					</Select>
					<Select name="city" class="form-control mb-3"
						aria-label="Default select example" id="city">
						<option disabled selected>Select city</option>
						{cityList.map(function (city, index) {
							return (
								<option value={city.id}>{city.name}</option>
							)
						})}
					</Select>
				</form>
				<button type="submit" name='customerdetails' onClick={registerCustomer} style={{ color: 'white' }}>Submit</button>

				{isCustomerLoading && <Spinner />}  {/* Show spinner while loading */}
				{customerRegistrationResponseMessage && <p>{customerRegistrationResponseMessage}</p>}
			</LeftSection>

			{/* Right Section for Cart and Checkout */}
			<RightSection>
				<SectionTitle>Your Cart</SectionTitle>
				<CartItemsContainer>
					{cart.length === 0 ? (
						<p>Your cart is empty</p>
					) : (
						cart.map((item) => (
							<CartItem key={item.id}>
								<ItemImage src={item.product_detail.main_image} alt={item.name} />
								<ItemDetails>
									<ItemName>{item.name}</ItemName>
									<ItemSize>Size: {item.size}</ItemSize>
								</ItemDetails>
								<span>${Number(item.price).toFixed(2)}</span>
							</CartItem>
						))
					)}
				</CartItemsContainer>
				{cart.length > 0 && (
					<>
						<TotalAmount>Total: ${total}</TotalAmount>
						<hr />
						<p>Delivery: {'200MZN'}</p>
						{/*<p>Moz, Maputo, Matola to {}</p>*/}
						<hr />
						<p>Payment options:</p>
						<hr />
						<MpesaCheckout onClick={openMpesaCheckout}>M-Pesa</MpesaCheckout>

						<MpesaContent isOpen={isMpesaOpen}>
							<p>Number please (e.g 84 ### ####):</p>
							<Input
								type="tel"
								placeholder="Enter phone number"
								pattern="[0-9]*"
								value={msisdn}
								onChange={(e) => setMsisdn(e.target.value)}
							/>
							<MpesaSubmitButton onClick={postMpesaPayment}>Submit</MpesaSubmitButton>
							{isLoading && <Spinner />}  {/* Show spinner while loading */}
							{responseMessage && <p>{responseMessage}</p>}
						</MpesaContent>
					</>
				)}
			</RightSection>
		</CheckoutContainer>
	);
};

export default Checkout;