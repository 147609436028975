import React, { useState } from "react";
import styles from '../../assets/styles/css/sb-admin-2.module.css'
import '../../assets/styles/css/sb-admin-2.min.css'
import skyJacketLogo from '../../assets/images/logos/sj_l.png'
import { Modal, Button } from 'react-bootstrap'
import Dropzone from 'react-dropzone';
import {
    Image,
    Container,
    Iframe,
    FilePreview,
    DragContainer,
    AceptedFiles,
} from '../../assets/js/styles';
import $ from 'jquery';

export default class LegalDocuments extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            legalDocs: [],
            legalDoc: {
                name: '',
                type: '',
                digital_doc: '',
            },
            showDigiDocViewModal: false,
        }

        this.fetchLegalDocuments = this.fetchLegalDocuments.bind(this)
        this.origin = window.location.origin;
    }

    closeEditModal = () => {
        this.setState({ showEditModal: false });
    };

    showDigiDocViewModal = () => {
        this.setState({ showDigiDocViewModal: true });
    };

    closeDigiDocViewModalModal = () => {
        this.setState({ showDigiDocViewModal: false });
    };

    componentWillMount() {
        this.fetchLegalDocuments();
    }

    fetchLegalDocuments() {
        fetch(this.constructURL("/api/list-legal-documentation/"))
            .then(response => response.json())
            .then(data =>
                this.setState({
                    legalDocs: data
                })
            )
    }

    createLegalDocument(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');
        var url = this.constructURL("/api/create-legal-document/");
        console.log(JSON.stringify(this.state.legalDoc));

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.legalDoc)
        }).then((response) => {
            this.fetchCustomers()
            this.setState({
                legalDoc: {
                    name: '',
                    type: '',
                    digital_doc: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeModal()

    }

    handleChangeOnEdit(e) {
        var { name, value } = e.target

        this.setState({
            editingDetails: {
                ...this.state.editingDetails,
                [name]: value,
            }
        })
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            legalDoc: {
                ...this.state.legalDoc,
                [name]: value,
            }
        })

    }

    onDrop(legalDoc) {
        this.state.legalDoc = legalDoc;
        console.log("----------------------")
        console.log(this.state.legalDoc)
    };

    imageInfoUtility(file) {
        if (!file) {
            return null;
        }

        const imageSize = file.size;
        const imageName = file.name;
        const imageType = file.type;

        return { imageSize, imageName, imageType };
    };

    updateImageList(newList) {
        //setList(newList);
        this.setState({
            image_list: newList
        })
    };

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    constructURL(destination) {
        var location = this.origin
        return location + destination
    }

    render() {
        var legalDocs = this.state.legalDocs
        var legalDoc = this.state.legalDoc

        return (
            <>
                <div class="container-fluid">
                    <h1 class="h3 mb-2 text-gray-800">Legal documents</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Documents</h6>

                            <form onSubmit={this.createLegalDocument} enctype="multipart/form-data">
                                <Modal.Header closeButton>
                                    <Modal.Title>Document</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="task" class="form-label">Name</label>
                                                <input type="text" name='name' value={this.state.legalDoc.name} onChange={this.handleChange} class="form-control"
                                                    placeholder="name" required />
                                            </div>
                                        </div>
                                        {/*<div class="col-md-4">
                                                <label for="task" class="form-label">Collection</label>
                                                <select name="collection_id" onChange={self.handleChange} class="form-control mb-3"
                                                    aria-label="Default select example" id="role">
                                                    <option disabled selected>Select the collection</option>
                                                    {docTypes.map(function(doType, index) {
                                                        return (
                                                            <option value={doType.id}>{doType.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>*?}
                                            <hr />
                                            {/*<DropzoneComponent />*/}
                                        <Dropzone onDrop={this.onDrop}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="container">
                                                    <DragContainer {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        <p>Drop Document here...</p>
                                                    </DragContainer>
                                                    <br>
                                                    </br>
                                                    <FilePreview>
                                                        <h4>Image:</h4>
                                                        <br>
                                                        </br>
                                                        <li key={this.state.legalDoc.name}>
                                                            {this.state.legalDoc.name} - {this.state.legalDoc.digital_doc.size}  - {this.state.legalDoc.type} bytes
                                                        </li>
                                                        <div>
                                                            {/*<Image src={URL.createObjectURL(this.state.legalDoc)} alt="preview" />*/}
                                                        </div>
                                                    </FilePreview>
                                                </div>
                                            )}
                                        </Dropzone>
                                        {/***********************/}
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Add Document</button>
                                </Modal.Footer>
                            </form>
                        </div>
                    </div>

                </div >

                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Available Documents:</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {legalDocs.map(function (digiDoc, index) {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{digiDoc.name}</td>
                                                    <td></td>
                                                    <td>
                                                        <div class="actions-container flex">
                                                            <a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => this.viewDigiDoc(digiDoc)} class="custom-action-button">
                                                                <i class="fas fa fa-eye"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                {/* ====== MODALS ====== */}
                <Modal show={this.state.showDigiDocViewModal} onHide={this.closeDigiDocViewModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Digi Doc</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Document.... </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDeleteModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>

        )
    }
}
