//import React from "react";
import React, { useState } from "react";
import styles from '../../assets/styles/css/sb-admin-2.module.css'
import '../../assets/styles/css/sb-admin-2.min.css'
import skyJacketLogo from '../../assets/images/logos/sj_l.png'
import { Modal, Button } from 'react-bootstrap'
import Dropzone from 'react-dropzone';
import {
	Image,
	Container,
	Iframe,
	FilePreview,
	DragContainer,
	AceptedFiles,
} from '../../assets/js/styles';
import $ from 'jquery';
//import Dropzone from 'react-dropzone-uploader'; // Import necessary module from react-dropzone
//import DropzoneComponent from './components/dragAndDropBox';
//import imageList from './components/dragAndDropBox';

class Inventory extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			warehouses: [],
			collections: [],
			stock_types: [],
			inventories: [],
			vendors: [],
			files: [],
			activeItem: {
				ip: null,
				name: '',
				inv_description: '',
				prod_description: '',
				quantity: '',
				stock_type: '',
				price: '',
				weight: '',
				digital: '',
				arrival_date: '',
				out_of_stock_date: '',
				warehouse_id: '',
				collection_id: '',
				vendor_id: '',
				images: [],
			},
			editingDetails: {
				ip: null,
				name: '',
				inv_description: '',
				prod_description: '',
				quantity: '',
				stock_type: '',
				price: '',
				weight: '',
				digital: '',
				arrival_date: '',
				out_of_stock_date: '',
				warehouse_id: '',
				collection_id: '',
				vendor_id: '',
				images: [],
			},
			editing: false,
			showCreateModal: false,
			showUpdateModal: false,
			showDeleteModal: false,
		}

		this.onDrop = (files) => {
			this.setState({ files })
			this.state.activeItem.images = files;
			//setFiles((prevFiles) => [
			//...prevFiles,
			//...files.map((file) =>
			//Object.assign(file, {
			//preview: URL.createObjectURL(file),
			//	})
			//	),
			//]);

			console.log("----------------------")
			console.log(this.state.activeItem.images)
			//console.log(files);
		};



		this.handleChange = this.handleChange.bind(this)
		this.fetchWarehouses = this.fetchWarehouses.bind(this)
		this.fetchCollections = this.fetchCollections.bind(this)
		this.fetchStockTypes = this.fetchStockTypes.bind(this)
		this.fetchIventory = this.fetchIventory.bind(this)
		this.fetchVendor = this.fetchVendor.bind(this)
		this.getCookie = this.getCookie.bind(this)
		this.createInventory = this.createInventory.bind(this)
		this.handleChangeOnEdit = this.handleChangeOnEdit.bind(this)
		this.updateInventory = this.updateInventory.bind(this)
		this.deleteInventory = this.deleteInventory.bind(this)
		this.origin = window.location.origin;
	}

	openCreateModal = () => {
		this.setState({ showCreateModal: true });
	};

	closeCreateModal = () => {
		this.setState({ showCreateModal: false });
	};

	openUpdateModal = () => {
		this.setState({ showUpdateModal: true });
	};

	closeUpdateModal = () => {
		this.setState({ showUpdateModal: false });
	};

	openDeleteModal = (role) => {
		this.setState({
			editingDetails: role,
			editing: true,
		});
		this.setState({ showDeleteModal: true });
	};

	closeDeleteModal = () => {
		this.setState({ showDeleteModal: false });
	};

	fetchIventory() {
		fetch(this.constructURL("/api/list-inventory/"))
			.then(response => response.json())
			.then(data =>
				this.setState({
					inventories: data
				})
			)
	}

	fetchVendor() {
		fetch(this.constructURL("/api/get-stock-types/"))
			.then(response => response.json())
			.then(data =>
				this.setState({
					stock_types: data
				})
			)
	}

	fetchWarehouses() {
		fetch(this.constructURL("/api/get-warehouses/"))
			.then(response => response.json())
			.then(data =>
				this.setState({
					warehouses: data
				})
			)
	}

	fetchCollections() {
		fetch(this.constructURL("/api/get-collections/"))
			.then(response => response.json())
			.then(data =>
				this.setState({
					collections: data
				})
			)
	}

	fetchStockTypes() {
		console.log("Fetching Stock Types...")
		fetch(this.constructURL("/api/get-stock-types/"))
			.then(response => response.json())
			.then(data =>
				this.setState({
					stock_types: data
				})
			)
	}

	componentWillMount() {
		this.fetchVendor()
		this.fetchWarehouses()
		this.fetchCollections()
		this.fetchIventory()
	}

	handleChange(e) {
		var { name, value } = e.target

		this.setState({
			activeItem: {
				...this.state.activeItem,
				[name]: value,
			}
		})
	}

	handleChangeOnEdit(e) {
		var { name, value } = e.target

		this.setState({
			editingDetails: {
				...this.state.editingDetails,
				[name]: value,
			}
		})
	}

	getCookie(name) {
		var cookieValue = null;
		if (document.cookie && document.cookie !== '') {
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i].trim();
				if (cookie.substring(0, name.length + 1) === (name + '=')) {
					cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
					break;
				}
			}
		}
		return cookieValue;
	}

	startEdit(role) {
		this.setState({
			editingDetails: role,
			editing: true,
		});

		this.openUpdateModal()
	}

	updateInventory(e) {
		e.preventDefault();

		var csrftoken = this.getCookie('csrftoken');
		var inventoryId = this.state.editingDetails.id
		var url = this.constructURL("/api/update-inventory/" + inventoryId + "/")

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrftoken,
			},
			body: JSON.stringify(this.state.editingDetails)
		}).then((response) => {
			this.fetchIventory()
			this.setState({
				editingDetails: {
					ip: null,
					name: '',
					inv_description: '',
					prod_description: '',
					quantity: '',
					stock_type: '',
					price: '',
					weight: '',
					digital: '',
					arrival_date: '',
					out_of_stock_date: '',
					warehouse_id: '',
					collection_id: '',
					vendor_id: '',
					images: [],
				}
			})
		}).catch(function(error) {
			console.log('ERROR', error);
		})

		//this.closeModal()
	}

	createInventory(e) {
		e.preventDefault();

		var csrftoken = this.getCookie('csrftoken');

		var url = this.constructURL("/api/create-inventory/");
		var formdata = new FormData();
		
		formdata.append('csrfmiddlewaretoken', csrftoken);
		formdata.append('data', this.state.activeItem);
		formdata.append('name', this.state.activeItem.name);
		formdata.append('inv_description', this.state.activeItem.inv_description);
		formdata.append('prod_description', this.state.activeItem.prod_description);
		formdata.append('quantity', this.state.activeItem.quantity);
		formdata.append('stock_type', this.state.activeItem.stock_type);
		formdata.append('price', this.state.activeItem.price);
		formdata.append('weight', this.state.activeItem.weight);
		formdata.append('digital', this.state.activeItem.digital);
		formdata.append('arrival_date', this.state.activeItem.arrival_date);
		formdata.append('out_of_stock_date', this.state.activeItem.out_of_stock_date);
		formdata.append('warehouse_id', this.state.activeItem.warehouse_id);
		formdata.append('collection_id', this.state.activeItem.collection_id);
		formdata.append('vendor_id', this.state.activeItem.vendor_id);
		formdata.append('main_image', this.state.activeItem.images[0]);
		formdata.append('cover_image', this.state.activeItem.images[1]);
		formdata.append('thumb1_image', this.state.activeItem.images[2]);
		formdata.append('thumb2_image', this.state.activeItem.images[3]);
		formdata.append('thumb3_image', this.state.activeItem.images[4]);

		// Example of making an AJAX request with jQuery
		$.ajax({
			type: 'POST',
			enctype: 'multipart/form-data',
			processData: false, // Important!
			contentType: false,
			cache: false,
			url: url,
			data: formdata,
			success: function(response) {
				$("#update-alert-success").removeAttr('hidden');
				console.log(response)
				//refreshAfterInterval(2000);
			},
			error: function(response) {
				$("#update-alert-error").removeAttr('hidden');
				console.log(response)
				//refreshAfterInterval(2000);
			}
		});

		/*fetch(url, {
			method: 'POST',
			enctype: 'multipart/form-data',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrftoken,
			},
			//body: JSON.stringify(this.state.activeItem),
			data: formdata,
		}).then((response) => {
			this.fetchIventory()
			this.setState({
				activeItem: {
					name: '',
					inv_description: '',
					prod_description: '',
					quantity: '',
					stock_type: '',
					price: '',
					weight: '',
					digital: '',
					arrival_date: '',
					out_of_stock_date: '',
					warehouse_id: '',
					collection_id: '',
					vendor_id: '',
					images: [],
				}
			})
		}).catch(function(error) {
			console.log('ERROR', error);
		})*/
	}

	deleteInventory(inventory) {
		var csrftoken = this.getCookie('csrftoken');

		var inventoryId = this.state.editingDetails.id
		var url = this.constructURL("/api/delete-inventory/" + inventoryId + "/")

		fetch(url, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrftoken,
			}
		}).then((response) => {
			this.fetchIventory();
		})

		this.closeDeleteModal()
	}

	constructURL(destination) {
		var location = this.origin
		return location + destination
	}

	/*onDrop = (acceptedFiles) => {
		const file = acceptedFiles[0];
		setUploadedImage(file);
	};*/

	imageInfoUtility = (file) => {
		if (!file) {
			return null;
		}

		const imageSize = file.size;
		const imageName = file.name;
		const imageType = file.type;

		return { imageSize, imageName, imageType };
	};

	updateImageList = (newList) => {
		//setList(newList);
		this.setState({
			image_list: newList
		})
	};

	render() {
		var self = this
		var inventories = self.state.inventories
		var warehouses = self.state.warehouses
		var collections = self.state.collections
		var stock_types = self.state.stock_types
		var vendors = self.state.vendors

		const files = this.state.files.map(file => (
			<li key={file.name}>
				{file.name} - {file.size} bytes
			</li>
		));

		const Preview = self.state.files.map(file => (
			<div>
				<Image src={URL.createObjectURL(file)} alt="preview" />
			</div>
		));

		return (
			<>
				<div class="container-fluid">
					<p>
						<a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
					</p>
					<h1 class="h3 mb-2 text-gray-800">Inventory</h1>
					<div class="card shadow mb-4">
						<div class="card-header py-3">
							<h6 class="m-0 font-weight-bold text-primary">Inventory</h6>

							<form onSubmit={this.createInventory} enctype="multipart/form-data">
								<Modal.Header closeButton>
									<Modal.Title>Add new inventory</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Name</label>
												<input type="text" name='name' value={this.state.activeItem.name} onChange={this.handleChange} class="form-control"
													placeholder="name" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Inventory Description</label>
												<input type="text" name='inv_description' value={this.state.activeItem.inv_description} onChange={this.handleChange} class="form-control"
													placeholder="inv_description" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Product Description</label>
												<input type="text" name='prod_description' value={this.state.activeItem.prod_description} onChange={this.handleChange} class="form-control"
													placeholder="prod_description" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Quantity</label>
												<input type="number" name='quantity' value={this.state.activeItem.quantity} onChange={this.handleChange} class="form-control"
													placeholder="quantity" required min="0" step="1" />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Price in MZN</label>
												<input type="number" name='price' value={this.state.activeItem.price} onChange={this.handleChange} class="form-control"
													placeholder="how much is it?" min="0" step="0.5" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Weight</label>
												<input type="number" name='weight' value={this.state.activeItem.weight} onChange={this.handleChange} class="form-control"
													placeholder="how much does it weigh?" min="0" step="0.1" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-check form-switch">
												<input class="form-check-input" name='digital' type="checkbox" role="switch" id="flexSwitchCheckDefault" />
												<label class="form-check-label" for="flexSwitchCheckDefault">Digital?</label>
											</div>
										</div>
										<hr />
										<div class="col-md-4">
											<label for="task" class="form-label">Stock Type</label>
											<select name="stock_type" onChange={self.handleChange} class="form-control mb-3"
												aria-label="Default select example" id="stockType_id">
												<option disabled selected>Select stock type</option>
												{stock_types.map(function(stockType, index) {
													return (
														<option value={stockType.value}>{stockType.value}</option>
													)
												})}
											</select>
										</div>
										<div class="col-md-4">
											<label for="task" class="form-label">Warehouse</label>
											<select name="warehouse_id" onChange={self.handleChange} class="form-control mb-3"
												aria-label="Default select example" id="role">
												<option disabled selected>Select the warehouse</option>
												{warehouses.map(function(warehouse, index) {
													return (
														<option value={warehouse.id}>{warehouse.name}</option>
													)
												})}
											</select>
										</div>
										<div class="col-md-4">
											<label for="task" class="form-label">Collection</label>
											<select name="collection_id" onChange={self.handleChange} class="form-control mb-3"
												aria-label="Default select example" id="role">
												<option disabled selected>Select the collection</option>
												{collections.map(function(collection, index) {
													return (
														<option value={collection.id}>{collection.name}</option>
													)
												})}
											</select>
										</div>
										<hr />
										{/*<DropzoneComponent />*/}
										<Dropzone onDrop={this.onDrop}>
											{({ getRootProps, getInputProps }) => (
												<div className="container">
													<DragContainer {...getRootProps({ className: 'dropzone' })}>
														<input {...getInputProps()} />
														<p>Drop something here...</p>
													</DragContainer>
													<br>
													</br>
													<FilePreview>
														<h4>Image:</h4>
														<br>
														</br>
														{Preview}
													</FilePreview>
												</div>
											)}
										</Dropzone>
										{/***********************/}
									</div>
								</Modal.Body>
								<Modal.Footer>
									<button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>New Inventory</button>
								</Modal.Footer>
							</form>
						</div>
					</div>

				</div >

				<div class="container-fluid">
					<div class="card shadow mb-4">
						<div class="card-header py-3">
							<h6 class="m-0 font-weight-bold text-primary">Inventory list</h6>
						</div>
						<div class="card-body">
							<div class="table-responsive">
								<table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="dataTable" width="100%" cellspacing="0">
									<thead>
										<tr>
											<th>#</th>
											<th>description</th>
											<th>quantiity</th>
											<th>stock type</th>
											<th>arrival date</th>
											<th>out of stock date</th>
											<th>warehouse</th>
											<th>actions</th>
										</tr>
									</thead>
									<tfoot>
										<tr>
											<th>#</th>
											<th>description</th>
											<th>quantiity</th>
											<th>stock type</th>
											<th>arrival date</th>
											<th>out of stock date</th>
											<th>warehouse</th>
											<th>actions</th>
										</tr>
									</tfoot>
									<tbody>
										{inventories.map(function(inventory, index) {
											return (
												<tr>
													<td>{index + 1}</td>
													<td>{inventory.description}</td>
													<td>{inventory.quantity}</td>
													<td>{inventory.stock_type}</td>
													<td>{inventory.arrival_date}</td>
													<td>{inventory.out_of_stock_date}</td>
													<td>{inventory.warehouse}</td>
													<td>
														<div class="actions-container flex">
															<a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.startEdit(inventory)} class="custom-action-button">
																<i class="fas fa fa-edit"></i>
															</a>
															<a class="custom-action-button" onClick={() => self.openDeleteModal(inventory)} style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }}>
																<i class="fas fa fa-trash-alt"></i>
															</a>
														</div>
													</td>
												</tr>
											)
										})}

									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
				{/* ====== MODALS ====== */}
				<Modal show={this.state.showCreateModal} onHide={this.closeCreateModal}>
					<form onSubmit={this.createInventory} onHide={this.closeCreateModal} show={this.state.showCreateModal}>
						<Modal.Header closeButton>
							<Modal.Title>Add new inventory</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<div class="alert alert-success" role="alert">
									Inventory created successfully!
								</div>
								<div class="alert alert-danger" role="alert">
									Could not create Inventory.
								</div>
							</div>
							<div class="table-responsive">
								<form id='form' onSubmit={this.createInventory}>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Name</label>
												<input type="text" name='name' value={this.state.activeItem.name} onChange={this.handleChange} class="form-control"
													placeholder="name" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Inventory Description</label>
												<input type="text" name='inv_description' value={this.state.activeItem.inv_description} onChange={this.handleChange} class="form-control"
													placeholder="inv_description" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Product Description</label>
												<input type="text" name='prod_description' value={this.state.activeItem.prod_description} onChange={this.handleChange} class="form-control"
													placeholder="prod_description" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Quantity</label>
												<input type="number" name='quantity' value={this.state.activeItem.quantity} onChange={this.handleChange} class="form-control"
													placeholder="quantity" required min="0" step="1" />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Price in MZN</label>
												<input type="number" name='price' value={this.state.activeItem.price} onChange={this.handleChange} class="form-control"
													placeholder="how much is it?" min="0" step="0.5" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-group">
												<label for="task" class="form-label">Weight</label>
												<input type="number" name='weight' value={this.state.activeItem.weight} onChange={this.handleChange} class="form-control"
													placeholder="how much does it weigh?" min="0" step="0.1" required />
											</div>
										</div>
										<div class="col-md-4">
											<div class="form-check form-switch">
												<input class="form-check-input" name='digital' type="checkbox" role="switch" id="flexSwitchCheckDefault" />
												<label class="form-check-label" for="flexSwitchCheckDefault">Digital?</label>
											</div>
										</div>
										<hr />
										<div class="col-md-4">
											<label for="task" class="form-label">Stock Type</label>
											<select name="stock_type" onChange={self.handleChange} class="form-control mb-3"
												aria-label="Default select example" id="stockType_id">
												<option disabled selected>Select stock type</option>
												{stock_types.map(function(stockType, index) {
													return (
														<option value={stockType.value}>{stockType.value}</option>
													)
												})}
											</select>
										</div>
										<div class="col-md-4">
											<label for="task" class="form-label">Warehouse</label>
											<select name="warehouse_id" onChange={self.handleChange} class="form-control mb-3"
												aria-label="Default select example" id="role">
												<option disabled selected>Select the warehouse</option>
												{warehouses.map(function(warehouse, index) {
													return (
														<option value={warehouse.id}>{warehouse.name}</option>
													)
												})}
											</select>
										</div>
										<div class="col-md-4">
											<label for="task" class="form-label">Collection</label>
											<select name="collection_id" onChange={self.handleChange} class="form-control mb-3"
												aria-label="Default select example" id="role">
												<option disabled selected>Select the collection</option>
												{collections.map(function(collection, index) {
													return (
														<option value={collection.id}>{collection.name}</option>
													)
												})}
											</select>
										</div>
										<hr />
										<div className="col-md-12">
											{/* Dropzonek */}
											{/*<DropzoneComponent />*/}
											{/* END */}
										</div>
									</div>
								</form>
							</div>
						</Modal.Body >
						<Modal.Footer>
							<Button variant="secondary" onClick={this.closeCreateModal}>
								Close
							</Button>
							<button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }} onClick={this.closeCreateModal}>Save</button>
						</Modal.Footer>
					</form >
				</Modal >
				{/* Update model */}
				< Modal show={this.state.showUpdateModal} onHide={this.closeUpdateModal} >

				</Modal >

				<Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
					<Modal.Header closeButton>
						<Modal.Title>Confirm</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Are you sure, you want to delete inventory?</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.closeDeleteModal}>
							Close
						</Button>
						<a onClick={() => this.deleteInventory(this.state.editingDetails)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
					</Modal.Footer>
				</Modal>

			</>

		)
	}

}

export default Inventory