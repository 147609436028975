import React from "react";
import ProductComp from '../../components/ProductComp.jsx';
import ProductDetail from './shopping/ProductDetail.js';
import { Routes, Route } from 'react-router-dom';

export default class Store extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: []
        };
    }
    
    // Fetch products from API
    fetchProducts() {
        var url = this.constructURL("/api/list-products/");
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ products: data }));
    }

    componentDidMount() {
        this.fetchProducts();
    }

    // Construct API URL
    constructURL(destination) {
        var location = window.location.origin;
        return location + destination;
    }

    render() {
        const { products } = this.state;
        var currencyDetails = 1;

        return (
            <>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>

                <div id="product-block" className="row">
                    {products.map((product, index) => (
                        <div className="col-sm-12 col-md-6 col-lg-3" key={index}>
                            <ProductComp
                                product={product}
                            />
                        </div>
                    ))}
                </div>

                <Routes>
                    <Route path='/customer/product-details/:productId' element={<ProductDetail />} />
                    <Route path='/view-product/:productId' element={<ProductDetail />} />
                </Routes>
            </>
        );
    }
}
