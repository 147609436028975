import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/css/sb-admin-2.module.css";
import "../../../assets/styles/css/sb-admin-2.min.css";

const Quotation = () => {
    const [users, setUsers] = useState([]);

    // Fetch users when the component mounts
    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = () => {
        fetch("https://skyjacket.co.za/api/users-list/")
            .then((response) => response.json())
            .then((data) => setUsers(data))
            .catch((error) => console.error("Error fetching users:", error));
    };

    const openPaySlipModal = (event) => {
        // Placeholder for the function
        console.log("Selected User:", event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form submitted");
    };

    return (
        <>
            <div className="container-fluid">
                <p>
                    <a href="/administration/dashboard" style={{ color: "grey" }}>
                        /dashboard
                    </a>
                </p>
                <h1 className="h3 mb-2 text-gray-800">Quotation</h1>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Quotation</h6>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <form id="form" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="role" className="form-label">Staff</label>
                                        <select
                                            name="user"
                                            onChange={openPaySlipModal}
                                            className={`${styles.formControl} mb-3`}
                                            aria-label="Default select example"
                                            id="role"
                                        >
                                            <option disabled selected>Select the staff role</option>
                                            {users.map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.username}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Quotation;
