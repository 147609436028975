import React from "react";
import styles from '../../assets/styles/css/sb-admin-2.module.css'
import '../../assets/styles/css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'
import logo from '../../assets/images/logos/sj_l.png';

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            customerData: [],
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Number of Customers',
                        data: [],
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    }
                ]
            }
        }

        this.fetchCustomers = this.fetchCustomers.bind(this)
        this.origin = window.location.origin;
    }

    closeEditModal = () => {
        this.setState({ showEditModal: false });
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    componentWillMount() {
        this.fetchCustomers();
    }

    fetchCustomers() {
        fetch(this.constructURL("/api/list-customers/"))
            .then(response => response.json())
            .then(data =>
                this.setState({
                    customers: data
                })
            )
    }

    handleChangeOnEdit(e) {
        var { name, value } = e.target

        this.setState({
            editingDetails: {
                ...this.state.editingDetails,
                [name]: value,
            }
        })
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })

    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    constructURL(destination) {
        var location = this.origin
        return location + destination
    }

    render() {
        var customers = this.state.customers

        return (
            <>
                <div class="container-fluid">
                    <h1 class="h3 mb-2 text-gray-800">Welcome to BaseZero!</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <div class="d-flex justify-content-between">
                                <div class="flex-grow-1 mr-2">

                                </div>
                                <div class="flex-grow-1 ml-2">
                                    <h1 class="h3 mb-2 text-gray-800">Check the list on your left for management options</h1>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">

                            </div>
                        </div>
                    </div>

                </div>
            </>
        )

    }
}
