import React from 'react'
import styles from '../../assets/styles/css/sb-admin-2.module.css'
import '../../assets/styles/css/sb-admin-2.min.css'
import { Modal, Button } from 'react-bootstrap'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'
import Department from './Department';

class Employee extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            employees: [],
            roles: [],
            departments: [],
            work_periods: [],
            job_titles: [],
            activeItem: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                det_department: '',
                det_salary: '',
                det_payment_account: '',
                det_nationality: '',
                det_date_of_birth: '',
                det_identification_number: '',
                det_identification_emission_date: '',
                det_identification_expiry_date: '',
                det_det_address: '',
                det_start_date: '',
                det_contract_duration: '',
                det_work_period: '',
            },
            employee: {
                id: null,
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                det_department: '',
                det_salary: '',
                det_payment_account: '',
                det_nationality: '',
                det_date_of_birth: '',
                det_identification_number: '',
                det_identification_emission_date: '',
                det_identification_expiry_date: '',
                det_det_address: '',
                det_start_date: '',
                det_contract_duration: '',
                det_work_period: '',
            },
            editEmployee: {
                id: null,
                first_name: '',
                last_name: '',
                username: '',
                email: '',
                phone: '',
                password: '',
                phone: '',
                status: '',
                country_id: '',
                det_department: '',
                det_salary: '',
                det_payment_account: '',
                det_nationality: '',
                det_date_of_birth: '',
                det_identification_number: '',
                det_identification_emission_date: '',
                det_identification_expiry_date: '',
                det_det_address: '',
                det_start_date: '',
                det_contract_duration: '',
                det_work_period: '',
            },
            showModal: false,
            showDeleteModal: false,
            /*employeeDetails: {
                idEmployee: null,
                type: '',
                value: '',
            },*/
            showModal: false,
            showDocGeneraterModal: false,
            showEditModal: false,
            showDeleteModal: false,
            //showEmployeeDetailsModal: false,
            show: false,
            destinationLink: '',
        }

        this.listAllEmployees = this.listAllEmployees.bind(this)
        //this.fetchRoles = this.fetchRoles.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.createEmployee = this.createEmployee.bind(this)
        this.handleChangeOnEdit = this.handleChangeOnEdit.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.updateEmployee = this.updateEmployee.bind(this)
        this.generateProfileFile = this.generateProfileFile.bind(this)
        this.generateJobOfferFile = this.generateJobOfferFile.bind(this)
        this.generateContractFile = this.generateContractFile.bind(this)
        this.generateConfidencialityAgreementFile = this.generateConfidencialityAgreementFile.bind(this)
        this.generatePayslipFile = this.generatePayslipFile.bind(this)
        this.generateDisciplinaryFile = this.generateDisciplinaryFile.bind(this)
        this.generateAwardFile = this.generateAwardFile.bind(this)
        this.fetchRoles = this.fetchRoles.bind(this)
        this.fetchJobTitles = this.fetchJobTitles.bind(this)
        this.fetchWorkPeriods = this.fetchWorkPeriods.bind(this)
        //this.handleChangeOnEmployeeDetails = this.handleChangeOnEmployeeDetails.bind(this)
        //this.createEmployeeEmployeeDetails = this.createEmployeeEmployeeDetails.bind(this)
        this.origin = window.location.origin;
    }

    constructURL(destination) {
        var location = this.origin;
        return location + destination;
    }

    showToast = () => {
        this.setState({ show: true }, () => {
            setTimeout(() => {
                this.setState({ show: false });
            }, 5000);
        });
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    openEmployeeDetailsModal = (employee) => {

        this.setState({
            employeeDetails: {
                idEmployee: employee.id
            },
            employee: {
                id: employee.id
            },
            showEmployeeDetailsModal: true
        });
    };

    closeEmployeeDetailsModal = () => {
        this.setState({ showEmployeeDetailsModal: false });
    };

    openEditModal = (employee) => {
        this.setState({
            employee: employee,
            showEditModal: true
        });
    };

    closeEditModal = () => {
        this.setState({ showEditModal: false });
    };

    openDocGeneraterModal = (employee) => {
        this.setState({
            employee: employee,
            showDocGeneraterModal: true
        });
    };

    closeDocGeneraterModal = () => {
        this.setState({ showDocGeneraterModal: false });
    };

    openDeleteModal = (employee) => {
        this.setState({
            employee: employee,
            editing: true,
        });
        this.setState({ showDeleteModal: true });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    };

    componentWillMount() {
        this.listAllEmployees();
        this.fetchRoles();
        this.fetchJobTitles();
        this.fetchWorkPeriods();
        this.fetchDepartments();
    }

    listAllEmployees() {
        fetch(this.constructURL("/api/list-employees/"))
            .then(response => response.json())
            .then(data =>
                this.setState({
                    employees: data
                })
            )
    }

    fetchRoles() {
        fetch(this.constructURL("/api/list-roles/"))
            .then(response => response.json())
            .then(data =>
                this.setState({
                    roles: data
                })
            )
    }

    fetchDepartments() {
        fetch(this.constructURL("/api/list-roles/"))
            .then(response => response.json())
            .then(data =>
                this.setState({
                    departments: data
                })
            )
    }

    fetchWorkPeriods() {
        fetch(this.constructURL("/api/list-work-hours/"))
            .then(response => response.json())
            .then(data =>
                this.setState({
                    work_periods: data
                })
            )
    }

    fetchJobTitles() {
        fetch(this.constructURL("/api/list-job-titles/"))
            .then(response => response.json())
            .then(data =>
                this.setState({
                    job_titles: data
                })
            )
    }
    
    handleChangeOnEmployeeDetails(e) {
        var { name, value } = e.target

        this.setState({
            employeeDetails: {
                ...this.state.employeeDetails,
                [name]: value,
            }
        })
    }

    handleChangeOnEdit(e) {
        var { name, value } = e.target

        this.setState({
            employee: {
                ...this.state.employee,
                [name]: value,
            }
        })
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })
    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    createEmployeeEmployeeDetails(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = this.constructURL("/api/create-employee-details/");

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.employeeDetails)
        }).then((response) => {
            this.listAllEmployees()
            this.setState({
                employeeDetails: {
                    id: null,
                    type: '',
                    value: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeEmployeeDetailsModal()
    }

    createEmployee(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var url = this.constructURL("/api/create-employee/");
        //console.log("Employee should be created")
        //console.log(this.state.activeItem)

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.listAllEmployees()
            this.setState({
                activeItem: {
                    id: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                }
            })
            //console.log("Employee should be created")
            //console.log(this.state.activeItem)
            //console.log(response)
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeModal()
    }

    updateEmployee(e) {
        e.preventDefault();

        var csrftoken = this.getCookie('csrftoken');

        var employeeId = this.state.employee.id
        var url = this.constructURL("/api/update-employee/" + employeeId + "/");


        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.employee)
        }).then((response) => {
            this.listAllEmployees()
            this.setState({
                employee: {
                    id: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeEditModal()
    }

    deleteEmployee(employee) {
        var csrftoken = this.getCookie('csrftoken');
        var employeeId = this.state.employee.id
        var url = this.constructURL("/api/delete-employee/" + employeeId + "/");

        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then((response) => {
            this.listAllEmployees();
        })

        this.closeDeleteModal()
    }

    generateProfileFile(e) {
        e.preventDefault();
        var csrftoken = this.getCookie('csrftoken');
        var url = this.constructURL("/api/generate-profile/" + this.state.employee.id);

        console.log(url);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                //link.setAttribute('download', `Profile_1.pdf`);
                link.setAttribute('download', `Profile_${this.state.employee.first_name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('There was an error downloading the file!', error);
            });

        //this.closeDeleteModal()
        //console.log('Generating file for employee:', this.state.employee.id);
    };

    generateJobOfferFile(e) {
        e.preventDefault();
        var csrftoken = this.getCookie('csrftoken');
        var url = this.constructURL("/api/generate-job-offer/" + this.state.employee.id);

        console.log(url);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                //link.setAttribute('download', `Profile_1.pdf`);
                link.setAttribute('download', `Job_offer_${this.state.employee.first_name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('There was an error downloading the file!', error);
            });

        //this.closeDeleteModal()
        //console.log('Generating file for employee:', this.state.employee.id);
    };

    generateContractFile(e) {
        e.preventDefault();
        var csrftoken = this.getCookie('csrftoken');
        var url = this.constructURL("/api/generate-contract/" + this.state.employee.id);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                //link.setAttribute('download', `Profile_1.pdf`);
                link.setAttribute('download', `Contract_${this.state.employee.first_name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('There was an error downloading the file!', error);
            });

        //this.closeDeleteModal()
        //console.log('Generating file for employee:', this.state.employee.id);
    };

    generateConfidencialityAgreementFile(e) {
        e.preventDefault();
        var csrftoken = this.getCookie('csrftoken');
        var url = this.constructURL("/api/generate-confidenciality-agreement/" + this.state.employee.id);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                //link.setAttribute('download', `Profile_1.pdf`);
                link.setAttribute('download', `Confidentiality_Agreement_${this.state.employee.first_name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('There was an error downloading the file!', error);
            });

        //this.closeDeleteModal()
        //console.log('Generating file for employee:', this.state.employee.id);
    };

    generatePayslipFile(e) {
        e.preventDefault();
        var csrftoken = this.getCookie('csrftoken');
        var url = this.constructURL("/api/generate-payslip/" + this.state.employee.id);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                //link.setAttribute('download', `Profile_1.pdf`);
                link.setAttribute('download', `Payslip_${this.state.employee.first_name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('There was an error downloading the file!', error);
            });

        //this.closeDeleteModal()
        //console.log('Generating file for employee:', this.state.employee.id);
    };

    generateDisciplinaryFile(e) {
        e.preventDefault();
        var csrftoken = this.getCookie('csrftoken');
        var url = this.constructURL("/api/generate-disciplinary/" + this.state.employee.id);
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                //link.setAttribute('download', `Profile_1.pdf`);
                link.setAttribute('download', `Disciplinary_${this.state.employee.first_name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('There was an error downloading the file!', error);
            });
    };

    generateAwardFile(e) {
        e.preventDefault();
        var csrftoken = this.getCookie('csrftoken');
        var url = this.constructURL("/api/generate-award/" + this.state.employee.id);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                //link.setAttribute('download', `Profile_1.pdf`);
                link.setAttribute('download', `Award_${this.state.employee.first_name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error('There was an error downloading the file!', error);
            });

        //this.closeDeleteModal()
        //console.log('Generating file for employee:', this.state.employee.id);
    };

    render() {

        var self = this
        var employees = self.state.employees
        var departments = self.state.departments
        var work_periods = self.state.work_periods
        var titles = self.state.job_titles

        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Register</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <div class="d-flex justify-content-between">
                                <div class="flex-grow-1 mr-2">
                                    <h6 class="m-0 font-weight-bold text-primary">list of employee</h6>
                                </div>
                                <div class="flex-grow-1 ml-2">
                                    <button onClick={() => this.openModal()}
                                        className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>New Employee</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="example1" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>name</th>
                                            <th>email</th>
                                            <th>status</th>
                                            <th>date joined</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>name</th>
                                            <th>email</th>
                                            <th>status</th>
                                            <th>date joined</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {employees.map(function (employee, index) {
                                            var date = new Date(employee.date_joined)
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{employee.first_name + ' ' + employee.last_name}</td>
                                                        <td>{employee.email}</td>
                                                        {employee.is_superemployee ? (
                                                            <td>superemployee</td>
                                                        ) : (
                                                            <td>employee</td>
                                                        )}
                                                        <td>{employee.date_joined}</td>
                                                        <td>
                                                            <div class="actions-container flex">
                                                                {/*<a onClick={() => self.openEditModal(employee)} class="custom-action-button" style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} >
                                                                    <i class="fas fa fa-edit"></i>
                                                                </a>*/}
                                                                {/*<a class="custom-action-button" onClick={() => self.openDeleteModal(employee)} style={{ cursor: 'pointer', color: 'grey' }}>
                                                                    <i class="fas fa fa-trash-alt"></i>
                                                                </a>*/}
                                                                <a onClick={() => self.openDocGeneraterModal(employee)} class="custom-action-button" style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} >
                                                                    <i class="fas fa fa-file"></i>
                                                                </a>
                                                                {/*<a href={`/administration/employeedetails/${employee.id}`} class="custom-action-button" style={{ cursor: 'pointer', fontSize: '19px', color: 'grey' }}>
                                                                    <i class="fas fa-ellipsis-h"></i>
                                                                </a>*/}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

                {/* ====== MODALS =======*/}
                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <form onSubmit={this.createEmployee} onHide={this.closeModal} show={this.state.showModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add new employee</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">
                                <div class={styles.sidebarHeading}>
                                    Collaborator Details
                                </div>
                                <hr class={styles.sidebarDivider}>
                                </hr>
                                <div class="col-sm-6">
                                    <label for="first_name" class="form-label">First Name</label>
                                    <input type="text" name='first_name' value={this.state.activeItem.first_name} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="last_name" class="form-label">Last Name</label>
                                    <input type="text" name='last_name' value={this.state.activeItem.last_name} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_date_of_birth" class="form-label">Date of Birth</label>
                                    <input type="date" name='det_date_of_birth' value={this.state.activeItem.det_date_of_birth} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_nationality" class="form-label">Nationality</label>
                                    <input type="text" name='det_nationality' value={this.state.activeItem.det_nationality} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_identification_number" class="form-label">ID or Passport Number</label>
                                    <input type="text" name='det_identification_number' value={this.state.activeItem.det_identification_number} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_identification_emission_date" class="form-label">ID or Passport emission date</label>
                                    <input type="date" name='det_identification_emission_date' value={this.state.activeItem.det_identification_emission_date} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_identification_expiry_date" class="form-label">ID or Passport expiry date</label>
                                    <input type="date" name='det_identification_expiry_date' value={this.state.activeItem.det_identification_expiry_date} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_address" class="form-label">Address</label>
                                    <input type="text" name='det_address' value={this.state.activeItem.det_address} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <br />
                                <div class={styles.sidebarHeading}>
                                    Collaborator Contact Details
                                </div>
                                <hr class={styles.sidebarDivider}>
                                </hr>
                                <div class="col-sm-6">
                                    <label for="phone" class="form-label">Phone number</label>
                                    <input type="number" name='phone' value={this.state.activeItem.phone} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" value={this.state.activeItem.email} name='email' onChange={this.handleChange} class="form-control" required />
                                </div>
                                <br />
                                <div class={styles.sidebarHeading}>
                                    Employment Details
                                </div>
                                <hr class={styles.sidebarDivider}>
                                </hr>
                                <div class="col-md-4">
                                    <label for="det_department" class="form-label">Department</label>
                                    <select name="det_department" onChange={self.handleChange} class="form-control mb-3"
                                        aria-label="Default select example" id="det_department">
                                        <option disabled selected>Select department</option>
                                        {departments.map(function (dep, index) {
                                            return (
                                                <option value={dep.value.name}>{dep.value.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <label for="det_job_title" class="form-label">Job Title</label>
                                    <select name="det_job_title" onChange={self.handleChange} class="form-control mb-3"
                                        aria-label="Default select example" id="det_job_title">
                                        <option disabled selected>Job title..</option>
                                        {titles.map(function (title, index) {
                                            return (
                                                <option value={title.value.name}>{title.value.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_start_date" class="form-label">Start Date</label>
                                    <input type="date" name='det_start_date' value={this.state.activeItem.det_start_date} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_contract_duration" class="form-label">Contract Duration</label>
                                    <input type="number" name='det_contract_duration' value={this.state.activeItem.det_contract_duration} onChange={this.handleChange} class="form-control"
													placeholder="Months.." required min="0" step="1" />
                                </div>
                                <div class="col-md-4">
                                    <label for="det_work_period" class="form-label">Work Hours</label>
                                    <select name="det_work_period" onChange={self.handleChange} class="form-control mb-3"
                                        aria-label="Default select example" id="det_work_period">
                                        <option disabled selected>Between..?</option>
                                        {work_periods.map(function (wp, index) {
                                            return (
                                                <option value={wp.value.name}>{wp.value.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_salary" class="form-label">Salary in MZN</label>
                                    <input type="number" name='det_salary' value={this.state.activeItem.det_salary} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="det_payment_account" class="form-label">Payment Account</label>
                                    <input type="number" value={this.state.activeItem.det_payment_account} name='det_payment_account' onChange={this.handleChange} class="form-control" required />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showEditModal} onHide={this.closeEditModal}>
                    <form onSubmit={this.updateEmployee}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit employee {this.state.employee.first_name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">First Name</label>
                                    <input type="text" name='first_name' value={this.state.employee.first_name} onChange={this.handleChangeOnEdit} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Last Name</label>
                                    <input type="text" name='last_name' value={this.state.employee.last_name} onChange={this.handleChangeOnEdit} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Email</label>
                                    <input type="email" value={this.state.employee.email} name='email' onChange={this.handleChangeOnEdit} class="form-control" required />
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeEditModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save Changes</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showDocGeneraterModal} onHide={this.closeDocGeneraterModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Generate document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="bg-white py-2 collapse-inner rounded">
                            <button className="btn btn-primary" onClick={this.generateProfileFile}>
                                <i className="fas fa-user"></i> Profile
                            </button><br />
                            <button className="btn btn-primary" onClick={this.generateJobOfferFile}>
                                <i className="fa fa-user-tie"></i> Job Offer
                            </button><br />
                            <button className="btn btn-primary" onClick={this.generateContractFile}>
                                <i className="fa fa-pen-fancy"></i> Contract
                            </button><br />
                            <button className="btn btn-primary" onClick={this.generateConfidencialityAgreementFile}>
                                <i className="fa fa-file-lock"></i> Confidentiality Agreement
                            </button><br />
                            <button className="btn btn-primary" onClick={this.generatePayslipFile}>
                                <i className="fa fa-file-invoice-dollar"></i> PaySlip
                            </button><br />
                            {/*<button className="btn btn-primary" onClick={this.generateDisciplinaryFile}>
                                <i className="fas fa-exclamation-triangle"></i> Disciplinary
                            </button><br />
                            <button className="btn btn-primary" onClick={this.generateAwardFile}>
                                <i className="fas fa-trophy"></i> Award
                            </button>*/}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDocGeneraterModal}
                            name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal >

                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Employee {this.state.employee.first_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure, you want to delete employee {this.state.employee.first_name}?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDeleteModal}>
                            Close
                        </Button>
                        <a onClick={() => this.deleteEmployee(this.state.employee)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
                    </Modal.Footer>
                </Modal>

                {/* =========== TOASTS ===========*/}
                <ToastContainer position="top-end" className="p-3">
                    <Toast show={this.state.show} onClose={() => this.setState({ show: false })}>
                        <Toast.Header>
                            <img src="..." className="rounded mr-2" alt="..." />
                            <strong className="mr-auto">Bootstrap</strong>
                            <small>11 mins ago</small>
                        </Toast.Header>
                        <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
                    </Toast>
                </ToastContainer>

            </>
        )
    }
}

export default Employee