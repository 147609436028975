import React from 'react';
import styled from "styled-components";

const styles = {
  productViewModalDialog: {
    maxWidth: '800px',
    width: '100%',
  },
  preCost: {
    textDecoration: 'line-through',
    color: '#a5a5a5',
  },
  spaceTen: {
    padding: '10px 0',
  },
  snackbar: {
    visibility: 'hidden',
    minWidth: '250px',
    marginLeft: '-125px',
    backgroundColor: '#006738',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '2px',
    padding: '16px',
    position: 'fixed',
    zIndex: '1',
    left: '50%',
    bottom: '30px',
    fontSize: '17px',
  },
  snackbarShow: {
    visibility: 'visible',
    WebkitAnimation: 'fadein 0.5s, fadeout 0.5s 2.5s',
    animation: 'fadein 0.5s, fadeout 0.5s 2.5s',
  },
  '@keyframes fadein': {
    from: { bottom: '0', opacity: '0' },
    to: { bottom: '30px', opacity: '1' },
  },
  '@keyframes fadeout': {
    from: { bottom: '30px', opacity: '1' },
    to: { bottom: '0', opacity: '0' },
  },
  // Define other styles similarly
};

export const Snackbar = styled.div`
    ${styles.snackbar}
`;

const MyComponent = () => {
  return (
    <div>
      <style>{`
        .product_view .modal-dialog {${styles.productViewModalDialog}}
        .pre-cost {${styles.preCost}}
        .space-ten {${styles.spaceTen}}
        #snackbar {${styles.snackbar}}
        #snackbar.show {${styles.snackbarShow}}
        @keyframes fadein {${styles['@keyframes fadein']}}
        @keyframes fadeout {${styles['@keyframes fadeout']}}
        /* Add other styles */
      `}</style>
      {/* Render your component JSX */}
    </div>
  );
};

export default MyComponent;
