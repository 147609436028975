import React from 'react'
import { Collapse } from 'react-bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.js'
import styles from '../../assets/styles/css/sb-admin-2.module.css'
import '../../assets/styles/css/sb-admin-2.min.css'
//import '../../assets/styles/js/sb-admin-2.js'
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard.js';
import AuthService from '../../features/auth/AuthService.js';
import Employee from './Employee.js';
import Customer from './Customer.js';

import Role from './Role.js';

import StaffDetails from './StaffDetails.js';
import UserProfile from '../../assets/icons/favicon.png'
import PaySlip from './documents/PaySlip.js'
import Profile from './documents/Profile.js'
import Contractual from './documents/Contractual.js'
import Disciplinary from './documents/Disciplinary.js'
import Quotation from './documents/Quotaion.js'
import Vacancy from './documents/Vacancy.js'
import html2pdf from 'html2pdf.js';
import logo from '../../assets/images/logos/sj_l.png';

import Measurement from './Measurement.js';
import Country from './geoinfo/Country.js';
import State from './geoinfo/State.js';
import City from './geoinfo/City.js';
import Location from './geoinfo/Location.js';
import Warehouse from './geoinfo/Warehouse.js';
import Shopping from './shopping/shopping_address.js';
import Department from './Department.js';
import Category from './Category.js';
import Brand from './Brand.js';
import Collection from './Collection.js';
import Vendor from './Vendor.js';
import Inventory from './Inventory.js';
import Order_x from './order/Order_x.js';
import Order_set from './order/Order_set.js';
import Product from './shopping/products/Product.js';
import ProductDetails from './shopping/products/ProductDetails.js';
import LegalDocuments from './LegalDocuments.js';

class TemplateAdmin extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            user: {
                isAuthenticated: false,
                data: {}
            },
            hideSideBar: false,
            open: false,
            openGeo: false,
            openProduct: false,
        }

    }

    handleToggle = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };

    handleToggleGeo = () => {
        this.setState(prevState => ({
            openGeo: !prevState.openGeo
        }));
    };

    handleToggleProduct = () => {
        this.setState(prevState => ({
            openProduct: !prevState.openProduct
        }));
    };

    handleToggleSideBar = () => {
        this.setState(prevState => ({
            hideSideBar: !prevState.hideSideBar
        }));
    };

    downloadQuotation = () => {
        const inputElement = document.getElementById('meu-template');
        const options = {
            filename: 'quotation.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        html2pdf(inputElement, options);
    }

    downloadVaga = () => {
        const inputElement = document.getElementById('vaga-template');
        const options = {
            filename: 'vaga.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        html2pdf(inputElement, options);
    }

    async componentDidMount() {
        try {
            var isAuthenticated = await AuthService.isAuthenticated();
            this.setState({
                user: {
                    isAuthenticated: isAuthenticated
                }
            })
        } catch (error) {
            return <Navigate to="/" />
        }
    }

    render() {
		var self = this
		var principal_user = self.user
        return (
            <Router>
                <div id="wrapper">
                    <ul className={` ${styles.navbarNav} ${styles.bgGradientPrimary} ${styles.sidebar} ${styles.sidebarDark} ${styles.accordion} ${this.state.hideSideBar ? 'd-none' : ''}`} id="accordionSidebar">
                        <Link className={`${styles.sidebarBrand} d-flex align-items-center justify-content-center`} to="/administration/dashboard">
                            <div className={`${styles.sidebarBrandText} mx-3`}>Base Zero</div>
                        </Link>

                        <hr className={`${styles.sidebarDivider} my-0`} />

                        <li className={styles.navItem}>
                            <a className={styles.navLink} href="/administration/dashboard">
                                <i class="fas fa-fw fa-tachometer-alt"></i>
                                <span>  Dashboard</span>
                            </a>
                        </li>

                        <hr class={styles.sidebarDivider}>
                        </hr>

                        <div class={styles.sidebarHeading}>
                            Sectors
                        </div>

                        <li className={styles.navItem}>
                            <a href='/administration/employee' className={`${styles.navLink} ${styles.collapsed}`} style={{ cursor: 'pointer' }}>
                                <i class="fa fa-users"></i>
                                <span>  Collaborator Management</span>
                            </a>
                        </li>

						<li className={styles.navItem}>
                            <a href='/administration/customer' className={`${styles.navLink} ${styles.collapsed}`} style={{ cursor: 'pointer' }}>
                                <i class="fa fa-heart"></i>
                                <span>  Customer/Client Relationship Management</span>
                            </a>
                        </li>

                        {/*<li className={styles.navItem}>
                            <a href='/administration/legal-documentation' className={`${styles.navLink} ${styles.collapsed}`} style={{ cursor: 'pointer' }}>
                                <i class="fa fa-gavel"></i>
                                <span>  Regulatory Complience Management</span>
                            </a>
                        </li>*/}
                        
                        <hr className={`${styles.sidebarDivider} d-none d-md-block`}>
                        </hr>

                        <div class="text-center d-none d-md-inline">
                            <button class={`${styles.roundedCircle} border-0`} id="sidebarToggle"></button>
                        </div>
                    </ul>

                    <div id="content-wrapper" class="d-flex flex-column">
                        <div id="content">

                            <nav className={`${styles.navbar} ${styles.navbarExpand} ${styles.navbarLight} bg-white ${styles.topbar} mb-4 static-top ${styles.shadow}`}>

                                <button id="sidebarToggleTop" className={`btn btn-link d-md-none ${styles.roundedCircle} mr-3`} onClick={() => this.handleToggleSideBar()}>
                                    <i class="fa fa-bars" style={{ color: 'black' }}></i>
                                </button>

                                <ul className={`${styles.navbarNav} ${styles.mlAuto}`}>

                                    <div className={`${styles.topbarDivider} d-none d-sm-block`}></div>

                                    <li className={`${styles.navItem} ${styles.dropdown} ${styles.noArrow}`}>
                                        <a className={`${styles.navLink} ${styles.dropdownToggle}`} href="/" id="userDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span class="mr-2 d-none d-lg-inline text-gray-600 small" id="username">{principal_user}</span>
                                            <img className={`${styles.imgProfile} ${styles.roundedCircle}`}
                                                src={UserProfile}
                                                alt="user-profile" />
                                        </a>
                                        <div className={`${styles.dropdownMenu} ${styles.dropdownMenuRight} ${styles.shadow} ${styles.animatedGrowIn}`}
                                            aria-labelledby="userDropdown">
                                            <a class="dropdown-item" href="/user-profile">
                                                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Profile
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#logout">
                                                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                                Logout
                                            </a>
                                        </div>
                                    </li>

                                </ul>

                            </nav>
                            {/* Routers */}
                            <Routes>
                                <Route path='/administration/dashboard' element={<Dashboard />}></Route>
                                <Route path='/administration/employee' element={<Employee />}></Route>
                                <Route path='/administration/customer' element={<Customer />}></Route>
                                <Route path='/administration/inventory' element={<Inventory />}></Route>
                                
                                {/** ======= Documents ========= */}
                                <Route path='/administration/payslip' element={<PaySlip />}></Route>
                                <Route path='/administration/profile' element={<Profile />}></Route>
                                <Route path='/administration/contractual' element={<Contractual />}></Route>
                                <Route path='/administration/disciplinary' element={<Disciplinary />}></Route>
                                <Route path='/administration/quotation' element={<Quotation />}></Route>
                                <Route path='/administration/vacancya' element={<Vacancy />}></Route>
                                <Route path='/administration/legal-documentation' element={<LegalDocuments />}></Route>

                                <Route path='/administration/measurement/:clientId' element={<Measurement />}></Route>
                                <Route path='/administration/staffdetails/:staffId' element={<StaffDetails />}></Route>

                                {/** ======= GEO ========= */}
                                <Route path='/administration/country' element={<Country />}></Route>
                                <Route path='/administration/state' element={<State />}></Route>
                                <Route path='/administration/city' element={<City />}></Route>
                                <Route path='/administration/location' element={<Location />}></Route>
                                <Route path='/administration/warehouse' element={<Warehouse />}></Route>

                                {/** ======= SHOPPING ====== */}
                                <Route path='/administration/shopping_address' element={<Shopping />}></Route>
                                <Route path='/administration/department' element={<Department />}></Route>
                                <Route path='/administration/category' element={<Category />}></Route>
                                <Route path='/administration/brand' element={<Brand />}></Route>
                                <Route path='/administration/collection' element={<Collection />}></Route>
                                <Route path='/administration/order-x' element={<Order_x />}></Route>
                                <Route path='/administration/order-set' element={<Order_set />}></Route>
                                <Route path='/administration/vendor' element={<Vendor />}></Route>
                                
                                <Route path='/administration/products-details' element={<ProductDetails />}></Route>
                                <Route path='/administration/products/:productDetailId' element={<Product />}></Route>
                            </Routes>
                            {/* end routers */}
                        </div>
                        <footer class="footer">
                            <div class="container-fluid clearfix">
                                <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © Sky Jacket
                                    Sociedade Unipessoal, Lda</span>
                            </div>
                        </footer>
                    </div >
                </div >
                <div class="d-none">
                    <div id="vaga-template" class="vaga-template">
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="ml-190 mt-60">
                                <img class="area-logo" src={logo} />
                                <div class="area-description mt-40">
                                    <p style={{ fontSize: '35px', marginLeft: '95px' }}>Sky Jacket</p>
                                    <p style={{ fontSize: '20px', marginLeft: '105px', marginTop: '-20px' }}><b>Anuncio de Vaga</b></p>
                                    <p style={{ fontSize: '20px', marginLeft: '105px', marginTop: '-20px' }}>titulo da vaga</p>
                                </div>
                            </div>
                            <p style={{ marginTop: '350px' }}></p>

                        </div>
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="description">
                                <div class="table-size">
                                    <div class="about ml-70 mt-60">
                                        <h2>About Sky Jacket</h2>
                                        <p style={{ textAlign: 'justify' }}>Sky Jacket, Sociedade Unipessoal, Lda is a Mozambican company specialized in the
                                            construction of clothing and in enhancing the function and practicality of inanimate objects by
                                            integrating them with Information Technologies (software and hardware). Our main objective is
                                            to offer IoT solutions and services.
                                            We are looking for a curious and dynamic young man to play the role of Computer Systems
                                            Programmer passionate about what he does and curious enough to try to implement unorthodox
                                            ideas.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div class="description">
                                <div class="table-size">
                                    <div class="about ml-70 mt-40">
                                        <h2>Requisitos</h2>
                                        <p style={{ textAlign: 'justify' }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="description">
                                <div class="table-size">
                                    <div class="about ml-70 mt-40">
                                        <h2>Responsabilidades</h2>
                                        <p style={{ textAlign: 'justify' }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="description">
                                <div class="table-size">
                                    <div class="about ml-70 mt-40">
                                        <h2>Por que trabalhar na Sky Jacket</h2>
                                        <p style={{ textAlign: 'justify' }}>
                                            1. Valorizamos a liberdade de experimentar desde que leve a um resultado lucrativo;<br />
                                            2. Oferecemos um ambiente favorável e flexível para aprendizado e trabalho;<br />
                                            3. Incentivamos a leitura de cursos/certificados de educação superior e estamos dispostos a
                                            ajudar no financiamento, desde que os objetivos do projeto sejam entregues com alta
                                            qualidade e no prazo acordado;<br />
                                            4. Se nossa equipe cresce, a Sky Jacket cresce. Queremos que você alcance seu maior
                                            potencial. Dessa forma, saberemos que estamos atingindo nosso maior potencial também.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="description">
                                <div class="table-size">
                                    <div class="about ml-70 mt-40">
                                        <h2>Documentação</h2>
                                        <p style={{ textAlign: 'justify' }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="description">
                                <div class="table-size">
                                    <div class="about ml-70 mt-40">
                                        <h2>Processo de candidatura</h2>
                                        <p style={{ textAlign: 'justify' }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ====== QUOTATION ====== */}
                <div class="d-none">
                    <div id="meu-template" className="meu-template">
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-size">
                                <div class="about ml-70 mt-40">
                                    <p style={{ marginLeft: '300px' }}><b>Date: 11/15/2022</b></p>
                                    <p style={{ marginLeft: '300px', marginTop: '-15px' }}><b>Quotation No: </b></p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-size">
                                <div class="about ml-70 mt-40">
                                    <p style={{ marginTop: '-20px' }}><b>Requested By: </b></p>
                                    <p style={{ marginTop: '-20px' }}><b>Customer Name: </b></p>
                                    <p style={{ marginTop: '-20px' }}><b>Telephone: </b></p>
                                    <p style={{ marginLeft: '300px', marginTop: '-70px' }}><b>Valid Until: 15days</b></p>
                                    <p style={{ marginLeft: '300px', marginTop: '-10px' }}><b>Submitted by: </b></p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive">
                                <table class="table table-bordered ml-70 table-size" style={{ width: '85%' }}>
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Description</th>
                                            <th>Back order</th>
                                            <th>Qty</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>0</td>
                                            <td>0.00</td>
                                            <td>0,00 MZN</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>0,00 MZN</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>0,00 MZN</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>0,00 MZN</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} style={{ borderBottom: '1px solid white', borderLeft: '1px solid white' }}></td>
                                            <td style={{ background: '#160f42', color: 'white' }}>Subtotal</td>
                                            <td>0,00 MZN</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} style={{ borderBottom: '1px solid white', borderLeft: '1px solid white' }}></td>
                                            <td>VAT/IVA 17%</td>
                                            <td>0,00 MZN</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} style={{ borderBottom: '1px solid white', borderLeft: '1px solid white' }}></td>
                                            <td colSpan={2} style={{ textAlign: 'center', background: '#160f42', color: 'white' }}>Total</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} style={{ borderBottom: '1px solid white', borderLeft: '1px solid white' }}></td>
                                            <td colSpan={2} style={{ textAlign: 'center' }}>0.00 MZN</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p class="ml-70"><b>Terms: 50% deposit required up front</b></p>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive">
                                <table class="table ml-70 table-size" style={{ width: '85%' }}>
                                    <thead>
                                        <tr>
                                            <th colSpan={2} style={{ textAlign: 'center', background: '#160f42', color: 'white' }}>Payment Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'center' }}><b>Nedbank</b></td>
                                            <td style={{ textAlign: 'center' }}><b>Mobile Banking</b></td>
                                        </tr>
                                        <tr>
                                            <td>Account Holder : Sky Jacket Sociedade Unip Lda</td>
                                            <td>Account Holder :Sky Jacket Sociedade Unip Lda</td>
                                        </tr>
                                        <tr>
                                            <td>Account No. : 00024212607</td>
                                            <td>Conta Movel: +258 83 321 7383</td>
                                        </tr>
                                        <tr>
                                            <td>NIB : 004300000002421260763</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th colSpan={2} style={{ textAlign: 'center', background: '#160f42', color: 'white' }}>Social Media</th>
                                        </tr>
                                        <tr>
                                            <td>Whatsapp: +258 83 321 7383</td>
                                            <td>Facebook : Sky Jacket</td>
                                        </tr>
                                        <tr>
                                            <td>Website: www.skyjacket.io</td>
                                            <td>Instagram : sky.jacket</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </Router>
        )
    }
}

export default TemplateAdmin