import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // If you're using react-router for navigation
import { CartContext } from './CartContext';

const ProductComp = ({ product }) => {
  const { addToCart, toggleCart } = useContext(CartContext); // Access context
  const navigate = useNavigate(); // Use the navigation hook

  // Local state
  const [showSizeSelection, setShowSizeSelection] = useState(false);
  const [image, setImage] = useState(product.product_detail.main_image);
  const [price, setPrice] = useState(product.price);
  const [showTooltip, setShowTooltip] = useState(false);

  // Method to handle adding product to the cart
  const handleAddToCart = (size) => {
    const productToAdd = { ...product, size };
    addToCart(productToAdd); // Add product with size to cart
    toggleCart();
  };

  // Method to handle hover effect for showing sizes and changing image
  const handleMouseEnterTile = () => {
    setImage(product.product_detail.cover_image);
    setShowSizeSelection(true);
    setPrice("");
  };

  // Method to handle mouse leave to revert back to original state
  const handleMouseLeaveTile = () => {
    setImage(product.product_detail.main_image);
    setShowSizeSelection(false);
    setPrice(product.price + "mt");
    setShowTooltip(false); // Hide tooltip
  };

  // Handle image click to navigate to product details
  const handleImageClick = () => {
    navigate(`/view-product/${product.reference_number}`);
  };

  return (
    <div
      className="product"
      onMouseEnter={handleMouseEnterTile}
      onMouseLeave={handleMouseLeaveTile}
      style={{
        border: '1px solid lightgrey',
        padding: '10px',
        margin: '10px',
        borderRadius: '5px'
      }}
    >
      <img
        className="thumbnail"
        src={image}
        style={{
          width: '300px',
          height: '300px',
          display: 'block',
          margin: 'auto',
          transition: 'opacity 0.5s ease-in-out',
          opacity: image === product.product_detail.main_image ? 1 : 0.8,
          cursor: 'pointer'
        }}
        alt={product.name}
        onClick={handleImageClick}
      />
      {!showSizeSelection ? (
        <h3 style={{ transition: 'color 0.5s ease-in-out' }}>
          {product.name}
        </h3>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
          {showTooltip && (
            <div style={{
              backgroundColor: 'black',
              color: 'white',
              padding: '5px',
              borderRadius: '5px',
              marginBottom: '10px',
              fontSize: '12px'
            }}>
              QUICK ADD
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <button onClick={() => handleAddToCart('S')}>S</button>
            <button onClick={() => handleAddToCart('M')}>M</button>
            <button onClick={() => handleAddToCart('L')}>L</button>
            <button onClick={() => handleAddToCart('XL')}>XL</button>
          </div>
        </div>
      )}
      <p>{price}</p>
    </div>
  );
};

export default ProductComp;
