import React, { useState } from 'react';
import '../../assets/styles/css/main.css';
import '../../assets/styles/css/util.css';
import '../../assets/styles/css/styles.css';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Landing from './Landing';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Store from './Store';
import ProductDetail from './shopping/ProductDetail';
import Checkout from './shopping/Checkout';
import SlidingCart from '../../components/SlidingCart';
import { useCart } from '../../components/CartContext'; // Import useCart
import logo from '../../assets/images/logos/sj_l.png';
import $ from 'jquery';

const Template = () => {
    const { isCartOpen, toggleCart } = useCart(); // Access isCartOpen and toggleCart from context
    const [searchQuery, setSearchQuery] = useState(''); // State for search input

    // Handle search query input
    const handleSearchInput = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleClick = () => {
        $('.menu-mobile').slideToggle();
    };

    return (
        <>
            <Router>
                <header>
                    <div className="container-menu-desktop">
                        <div className="wrap-menu-desktop bg-gradient-primary">
                            <nav className="limiter-menu-desktop container">
                                <NavLink to="/" className="logo">
                                    <img src={logo} alt="Sky Jacket" />
                                </NavLink>

                                <div className="menu-desktop">
                                    <ul className="main-menu">
                                        <li className={window.location.pathname === '/' ? 'active-menu' : ''}>
                                            <NavLink to="/">Home</NavLink>
                                        </li>
                                        <li className={window.location.pathname === '/about_us' ? 'active-menu' : ''}>
                                            <NavLink to="/about_us">About</NavLink>
                                        </li>
                                        <li className={window.location.pathname === '/contact_us' ? 'active-menu' : ''}>
                                            <NavLink to="/contact_us">Contact</NavLink>
                                        </li>
                                        {/*<li className={window.location.pathname === '/store' ? 'active-menu' : ''}>
                                            <NavLink to="/store">Store</NavLink>
                                        </li>*/}
                                    </ul>
                                </div>

                                <div className="wrap-icon-header flex-w flex-r-m">
                                    {/* Search Bar */}
                                    {/*
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={searchQuery}
                                        placeholder="Search..."
                                        onChange={handleSearchInput}
                                        style={{ width: '200px', marginRight: '10px' }}
                                    />
                                    */}

                                    <li onClick={() => this.handleClick()}
                                        className='btn btn-primary'
                                        >
                                        <a href="/login">Account</a>
                                    </li>

                                    {/* Toggle cart visibility */}
                                    <button className='btn btn-primary' onClick={toggleCart}>
                                        <i className="fas fa-shopping-cart"></i>
                                    </button>
                                </div>
                                <SlidingCart isOpen={isCartOpen} />
                            </nav>
                        </div>
                    </div>
                </header>

                <main className="content-body" style={{ marginTop: '0px', padding: '0px' }}>
                    <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/about_us" element={<AboutUs />} />
                        <Route path="/contact_us" element={<ContactUs />} />
                        <Route path="/store" element={<Store />} />
                        <Route path="/checkout" element={<Checkout />} />
                        <Route path="/view-product/:productId" element={<ProductDetail />} />
                    </Routes>
                </main>

                <footer className="footer py-4">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 text-lg-start">Copyright &copy; Sky Jacket 2022-2024</div>
                            <div className="col-lg-4 my-3 my-lg-0">
                                <a className="btn btn-dark btn-social mx-2" href="#"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-dark btn-social mx-2" href="#"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-dark btn-social mx-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                            <div className="col-lg-4 text-lg-end">
                                <a className="link-dark text-decoration-none me-3" href="#!">Privacy Policy</a>
                                <a className="link-dark text-decoration-none me-3" href="#!">Terms of Use</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </Router>
        </>
    );
};

export default Template;
