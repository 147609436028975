import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCart } from './CartContext';

// Create the CartContext
export const CartSliderContext = createContext();

// Custom hook for consuming CartContext
export const useCartSlider = () => useContext(CartSliderContext);

// Styled Components for layout and design
const Overlay = styled.div`
    display: ${({ isCartOpen }) => (isCartOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
`;

const SidebarContainer = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    height: 100%;
    background: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transform: ${({ isCartOpen }) => (isCartOpen ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.3s ease-in-out;
    z-index: 11;
`;

const SidebarHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const Title = styled.h2`
    margin: 0;
    font-size: 1.2rem;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
`;

const SidebarContent = styled.div`
    padding: 10px;
`;

const CartItems = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const CartItem = styled.li`
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const ItemImage = styled.img`
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
`;

const ItemDetails = styled.div`
    flex: 1;
`;

const ItemName = styled.p`
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
`;

const ItemSize = styled.p`
    margin: 5px 0;
    font-size: 0.9rem;
    color: gray;
`;

const ItemQuantity = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.9rem;
`;

const QuantityButton = styled.button`
    width: 25px;
    height: 25px;
    background: #ddd;
    border: none;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;

    &:hover {
        background: #ccc;
    }
`;

const CartTotal = styled.div`
    padding: 10px;
    font-weight: bold;
    font-size: 1.1rem;
    border-top: 1px solid #ddd;
    margin-top: 10px;
`;

const CheckoutButton = styled.button`
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #4caf50;
    color: white;
    font-size: 1rem;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;

const ClearCartButton = styled.button`
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #d32f2f;
    }
`;

const SlidingCart = () => {
    const { cart, removeFromCart, clearCart, isCartOpen, toggleCart, incrementItem, decrementItem } = useCart();
    const navigate = useNavigate();
    const handleCheckout = () => {
        toggleCart();
        navigate('/checkout'); // Navigate to the checkout page
    };

    // Calculate total price of all cart items
    //const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
    const total = cart.reduce((acc, item) => acc + Number(item.price), 0).toFixed(2);

    return (
        <>
            <Overlay isCartOpen={isCartOpen} onClick={toggleCart} />
            <SidebarContainer isCartOpen={isCartOpen}>
                <SidebarHeader>
                    <Title>Shopping Cart</Title>
                    <CloseButton onClick={toggleCart}>Close</CloseButton>
                </SidebarHeader>
                <SidebarContent>
                    {cart.length === 0 ? (
                        <p>Your cart is empty</p>
                    ) : (
                        <CartItems>
                            {cart.map((item) => (
                                <CartItem key={item.id}>
                                    <ItemImage src={item.product_detail.main_image} alt={item.name} />
                                    <ItemDetails>
                                        <ItemName>{item.name}</ItemName>
                                        <ItemSize>Size: {item.size}</ItemSize>
                                        {/*<ItemQuantity>
                                            <QuantityButton onClick={() => decrementItem(item.id)}>-</QuantityButton>
                                            
                                            <QuantityButton onClick={() => incrementItem(item.id)}>+</QuantityButton>
                                        </ItemQuantity>*/}
                                    </ItemDetails>
                                    {/*<span>${(item.price * item.quantity).toFixed(2)}</span>*/}
                                    <span>${Number(item.price).toFixed(2)}</span>
                                    {/*<RemoveButton onClick={() => removeFromCart(item.id)}>Remove</RemoveButton>*/}
                                </CartItem>
                            ))}
                        </CartItems>
                    )}
                    {cart.length > 0 && (
                        <>
                            <CartTotal>Total: ${total}</CartTotal>
                            <CheckoutButton onClick={handleCheckout}>Checkout</CheckoutButton>
                        </>
                    )}
                    <ClearCartButton onClick={clearCart}>Clear Cart</ClearCartButton>
                </SidebarContent>
            </SidebarContainer>
        </>
    );
};

export default SlidingCart;
