import React from "react";
import styles from '../../../assets/styles/custom/style';

export default class Store extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			product: {
				id: '',
				name:'',
				description:'',
				product_detail:[],
				price:'',
				collection:[],
				status:'',
				inventory:[],
			},
			currencyDetails: ''
		}
		
		this.fetchProductDetails = this.fetchProductDetails.bind(this);
		this.fetchCurrencyDetails = this.fetchCurrencyDetails.bind(this);
		this.origin = window.location.origin;
		this.pathName = window.location.pathname;
		this.pathProductReference = this.pathName.split("/")[2];
	}

	fetchProductDetails() {
		fetch(this.constructURL("/api/view-product/" + this.pathProductReference))
			.then(response => response.json())
			.then(data =>
				this.setState({
					product: data
				})
			)
	}

	fetchCurrencyDetails() {
		console.log('#########');
		fetch(this.constructURL("/api/get-currency-details/"))
			.then(response => response.json())
			.then(data =>
				this.setState({
					currencyDetails: data
				})
			)
			
			console.log(this.state.currencyDetails);
	}

	componentWillMount() {
		this.fetchProductDetails();
		this.fetchCurrencyDetails();
	}
	
	addToCart(product) {
		//console.log(product + 'added to cart')
	}
	
	constructURL(destination) {
		var location = this.origin
		return location + destination
	}

	render() {
		var self = this;
		var product = self.state.product;
		var currencyDetails = self.state.currencyDetails;
		var currencySymbol = currencyDetails.symbol;
		var currencyRate = currencyDetails.rate;
		
		console.log('Currency')
		console.log(self.state.currencyDetails);
		console.log(currencySymbol);
		console.log(currencyRate);
		
		return (
			<>
				<br />
				<header>
					<a href="{% url 'store' %}" className="btn btn-dark" style={{ marginRight: '25px' }} /*{{ marginLeft: '25px' }}*/><i className="fa fa-arrow-left"></i></a>
				</header>
				<div className="container">
					<br />
					<div className="row">
						{/* Add product image */}
						<div className="col-sm-12 col-md-10 col-lg-6 product_img">
							<center>
								<img id="featured" src={product.product_detail.main_image} alt="Product Main Image" />
								<div id="slide-wrapper">
									{/* Add thumbnails */}
								</div>
							</center>
						</div>
						{/* Add product content */}
						<div className="col-sm-12 col-md-2 col-lg-6 product_content">
							<br /><br /><br />
							<hr />
							<h2>{product.name}</h2>
							<hr />
							<div className="rating">
								<span className="glyphicon glyphicon-star"></span>
								<span className="glyphicon glyphicon-star"></span>
								<span className="glyphicon glyphicon-star"></span>
								<span className="glyphicon glyphicon-star"></span>
								<span className="glyphicon glyphicon-star"></span>
							</div>
							{/*<b><span className={{}}>{}: </span></b>{ }<br />*/}
							<b><span className={{}}>Collection: </span></b>{product.collection.name }
							<p>{product.product_detail.description }</p>
							<br /><br />
							<hr />
							<h3 className="cost">
								<span className="glyphicon glyphicon-usd"></span>
								{currencySymbol} {product.price  * currencyRate}
							</h3>
							<div className="space-ten"></div>
							<div className="btn-ground">
								{/* Add buttons */}
								<a onClick={() => this.addToCart(product)} data-product={product.id} data-name={product.name} data-action="add" className="btn btn-outline-primary update-cart" style={{}}>Press me!<i className={{}}></i></a>
								<a href="https://api.whatsapp.com/send?phone=258833217383" className="btn btn-outline-success call-to-action-btn" data-name="whatsapp">More Info | Whatsapp <i className="fa fa-whatsapp social-icon"></i></a>
							</div>
						</div>
					</div>
					<br /><br /><br />
				</div>

			</>
		)
	}
}