import React from "react";
import styles from '../../assets/styles/css/sb-admin-2.module.css'
import '../../assets/styles/css/sb-admin-2.min.css'
import logo from '../../assets/images/logos/sj_l.png'
import { Modal, Button } from 'react-bootstrap'
import html2pdf from 'html2pdf.js';
import '../../assets/styles/css/geometric.css'

export default class Measurement extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            client: {},
            measurement: [],
            activeItem: {
                id: null,
                yoke: '',
                back: '',
                shoulder: '',
                h_upper_chest: '',
                h_chest: '',
                h_lower_chest: '',
                h_stomach: '',
                h_waist: '',
                h_hip: '',
                armscye: '',
                h_bicept: '',
                h_elbow: '',
                h_wrist: '',
                h_thigh: '',
                h_knee: '',
                h_anckle: '',
                l_upper_chest: '',
                l_chest: '',
                l_lower_chest: '',
                l_stomach: '',
                l_waist: '',
                l_hip: '',
                l_bicept: '',
                l_elbow: '',
                l_wrist: '',
                l_thigh: '',
                l_knee: '',
                l_anckle: '',
            },
            showModal: false,
            showEditModal: false,
            showDeleteModal: false,
            counter: 0,
        }

        this.getClientData = this.getClientData.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getCookie = this.getCookie.bind(this)
        this.handleSubmitOnEdit = this.handleSubmitOnEdit.bind(this)
        this.downloadPDF = this.downloadPDF.bind(this)
    }

    componentWillMount() {
        this.getClientData()
        this.fetchMeasurements()
    }

    openEditModal = (measurement) => {
        this.setState({
            activeItem: measurement,
            showEditModal: true
        });
    };

    closeEditModal = () => {
        this.setState({ showEditModal: false });
        this.clear()
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    openDeleteModal = (measurement) => {
        this.setState({
            activeItem: measurement,
        });
        this.setState({ showDeleteModal: true });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false });
    };

    getClientId = () => {
        var url = window.location.href
        var arrayUrl = url.split('/')
        var clientId = arrayUrl[5]
        return clientId
    }

    getClientData() {
        fetch(`https://skyjacket.co.za/api/get-client-data/${this.getClientId()}/`)
            .then(response => response.json())
            .then(data =>
                this.setState({
                    client: data
                })
            )
    }

    fetchMeasurements() {
        fetch(`https://skyjacket.co.za/api/get-measurements/${this.getClientId()}/`)
            .then(response => response.json())
            .then(data =>
                this.setState({
                    measurement: data
                })
            )
    }

    forceUpdate = () => {
        this.setState({ counter: this.state.counter + 1 });
    };

    downloadPDF = (measurement) => {

        this.state.activeItem = measurement
        this.forceUpdate()
        console.log(this.state.activeItem);
        const inputElement = document.getElementById('meu-template');
        const options = {
            filename: 'client-profile.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        html2pdf(inputElement, options);
    }

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })
    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    clear() {
        this.setState({
            activeItem: {
                id: null,
                yoke: '',
                back: '',
                shoulder: '',
                h_upper_chest: '',
                h_chest: '',
                h_lower_chest: '',
                h_stomach: '',
                h_waist: '',
                h_hip: '',
                armscye: '',
                h_bicept: '',
                h_elbow: '',
                h_wrist: '',
                h_thigh: '',
                h_knee: '',
                h_anckle: '',
                l_upper_chest: '',
                l_chest: '',
                l_lower_chest: '',
                l_stomach: '',
                l_waist: '',
                l_hip: '',
                l_bicept: '',
                l_elbow: '',
                l_wrist: '',
                l_thigh: '',
                l_knee: '',
                l_anckle: '',
            }
        })
    }


    handleSubmit(e) {
        e.preventDefault()

        var csrftoken = this.getCookie('csrftoken');

        var url = `https://skyjacket.co.za/api/post-measurement/${this.getClientId()}/`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.fetchMeasurements()
            this.setState({
                activeItem: {
                    yoke: '',
                    back: '',
                    shoulder: '',
                    h_upper_chest: '',
                    h_chest: '',
                    h_lower_chest: '',
                    h_stomach: '',
                    h_waist: '',
                    h_hip: '',
                    armscye: '',
                    h_bicept: '',
                    h_elbow: '',
                    h_wrist: '',
                    h_thigh: '',
                    h_knee: '',
                    h_anckle: '',
                    l_upper_chest: '',
                    l_chest: '',
                    l_lower_chest: '',
                    l_stomach: '',
                    l_waist: '',
                    l_hip: '',
                    l_bicept: '',
                    l_elbow: '',
                    l_wrist: '',
                    l_thigh: '',
                    l_knee: '',
                    l_anckle: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeModal()
    }

    handleSubmitOnEdit(e) {
        e.preventDefault()

        var csrftoken = this.getCookie('csrftoken');

        var url = `https://skyjacket.co.za/api/update-measurement/${this.state.activeItem.id}/`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.fetchMeasurements()
            this.setState({
                activeItem: {
                    yoke: '',
                    back: '',
                    shoulder: '',
                    h_upper_chest: '',
                    h_chest: '',
                    h_lower_chest: '',
                    h_stomach: '',
                    h_waist: '',
                    h_hip: '',
                    armscye: '',
                    h_bicept: '',
                    h_elbow: '',
                    h_wrist: '',
                    h_thigh: '',
                    h_knee: '',
                    h_anckle: '',
                    l_upper_chest: '',
                    l_chest: '',
                    l_lower_chest: '',
                    l_stomach: '',
                    l_waist: '',
                    l_hip: '',
                    l_bicept: '',
                    l_elbow: '',
                    l_wrist: '',
                    l_thigh: '',
                    l_knee: '',
                    l_anckle: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closeEditModal()
    }

    deleteItem(measurement) {
        var csrftoken = this.getCookie('csrftoken');

        fetch(`https://skyjacket.co.za/api/delete-measurements/${measurement.id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then((response) => {
            this.fetchMeasurements();
        })

        this.closeDeleteModal()
    }

    render() {

        var self = this
        var measurements = this.state.measurement

        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Measurements</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <div class="d-flex justify-content-between">
                                <div class="flex-grow-1 mr-2">
                                    <h6 class="m-0 font-weight-bold text-primary">{this.state.client.username} measurements</h6>
                                </div>
                                <div class="flex-grow-1 ml-2">
                                    <button onClick={() => this.openModal()}
                                        className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}><i class="fa fa-plus" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="example1" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>created on</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {measurements.map(function (measurement, index) {
                                            var date = new Date(measurement.created_on)
                                            return (
                                                <tr>
                                                    <th>{index + 1}</th>
                                                    <th>{date.toLocaleDateString()}</th>
                                                    <th>
                                                        <a onClick={() => self.openEditModal(measurement)} class="custom-action-button" style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} >
                                                            <i class="fas fa fa-eye"></i>
                                                        </a>
                                                        <a class="custom-action-button" onClick={() => self.openDeleteModal(measurement)} style={{ cursor: 'pointer', color: 'grey', marginRight: '10px' }}>
                                                            <i class="fas fa fa-trash-alt"></i>
                                                        </a>
                                                        <a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.downloadPDF(measurement)} class="custom-action-button">
                                                            <i class="fa fa-download"></i>
                                                        </a>
                                                    </th>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>created on</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

                <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <form onSubmit={this.handleSubmit} onHide={this.closeModal} show={this.state.showModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add new measurements</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <label for="yoke" class="form-label">yoke</label>
                                    <input type="number" name='yoke' value={this.state.activeItem.yoke} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="back" class="form-label">back</label>
                                    <input type="number" name='back' value={this.state.activeItem.back} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="shoulder" class="form-label">shoulder</label>
                                    <input type="number" name='shoulder' value={this.state.activeItem.shoulder} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_upper_chest" class="form-label">h_upper_chest</label>
                                    <input type="number" name='h_upper_chest' value={this.state.activeItem.h_upper_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_chest" class="form-label">h_chest</label>
                                    <input type="number" name='h_chest' value={this.state.activeItem.h_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_lower_chest" class="form-label">h_lower_chest</label>
                                    <input type="number" name='h_lower_chest' value={this.state.activeItem.h_lower_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_stomach" class="form-label">h_stomach</label>
                                    <input type="number" name='h_stomach' value={this.state.activeItem.h_stomach} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_waist" class="form-label">h_waist</label>
                                    <input type="number" name='h_waist' value={this.state.activeItem.h_waist} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_hip" class="form-label">h_hip</label>
                                    <input type="number" name='h_hip' value={this.state.activeItem.h_hip} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="armscye" class="form-label">armscye</label>
                                    <input type="number" name='armscye' value={this.state.activeItem.armscye} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_bicept" class="form-label">h_bicept</label>
                                    <input type="number" name='h_bicept' value={this.state.activeItem.h_bicept} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_elbow" class="form-label">h_elbow</label>
                                    <input type="number" name='h_elbow' value={this.state.activeItem.h_elbow} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_wrist" class="form-label">h_wrist</label>
                                    <input type="number" name='h_wrist' value={this.state.activeItem.h_wrist} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_thigh" class="form-label">h_thigh</label>
                                    <input type="number" name='h_thigh' value={this.state.activeItem.h_thigh} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_knee" class="form-label">h_knee</label>
                                    <input type="number" name='h_knee' value={this.state.activeItem.h_knee} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_anckle" class="form-label">h_anckle</label>
                                    <input type="number" name='h_anckle' value={this.state.activeItem.h_anckle} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_upper_chest" class="form-label">l_upper_chest</label>
                                    <input type="number" name='l_upper_chest' value={this.state.activeItem.l_upper_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_chest" class="form-label">l_chest</label>
                                    <input type="number" name='l_chest' value={this.state.activeItem.l_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_lower_chest" class="form-label">l_lower_chest</label>
                                    <input type="number" name='l_lower_chest' value={this.state.activeItem.l_lower_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_stomach" class="form-label">l_stomach</label>
                                    <input type="number" name='l_stomach' value={this.state.activeItem.l_stomach} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_waist" class="form-label">l_waist</label>
                                    <input type="number" name='l_waist' value={this.state.activeItem.l_waist} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_hip" class="form-label">l_hip</label>
                                    <input type="number" name='l_hip' value={this.state.activeItem.l_hip} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_bicept" class="form-label">l_bicept</label>
                                    <input type="number" name='l_bicept' value={this.state.activeItem.l_bicept} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_elbow" class="form-label">l_elbow</label>
                                    <input type="number" name='l_elbow' value={this.state.activeItem.l_elbow} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_wrist" class="form-label">l_wrist</label>
                                    <input type="number" name='l_wrist' value={this.state.activeItem.l_wrist} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_thigh" class="form-label">l_thigh</label>
                                    <input type="number" name='l_thigh' value={this.state.activeItem.l_thigh} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_knee" class="form-label">l_knee</label>
                                    <input type="number" name='l_knee' value={this.state.activeItem.l_knee} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_anckle" class="form-label">l_anckle</label>
                                    <input type="number" name='l_anckle' value={this.state.activeItem.l_anckle} onChange={this.handleChange} class="form-control" />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showEditModal} onHide={this.closeEditModal}>
                    <form onSubmit={this.handleSubmitOnEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>See and update</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <label for="yoke" class="form-label">yoke</label>
                                    <input type="number" name='yoke' value={this.state.activeItem.yoke} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="back" class="form-label">back</label>
                                    <input type="number" name='back' value={this.state.activeItem.back} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="shoulder" class="form-label">shoulder</label>
                                    <input type="number" name='shoulder' value={this.state.activeItem.shoulder} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_upper_chest" class="form-label">h_upper_chest</label>
                                    <input type="number" name='h_upper_chest' value={this.state.activeItem.h_upper_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_chest" class="form-label">h_chest</label>
                                    <input type="number" name='h_chest' value={this.state.activeItem.h_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_lower_chest" class="form-label">h_lower_chest</label>
                                    <input type="number" name='h_lower_chest' value={this.state.activeItem.h_lower_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_stomach" class="form-label">h_stomach</label>
                                    <input type="number" name='h_stomach' value={this.state.activeItem.h_stomach} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_waist" class="form-label">h_waist</label>
                                    <input type="number" name='h_waist' value={this.state.activeItem.h_waist} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_hip" class="form-label">h_hip</label>
                                    <input type="number" name='h_hip' value={this.state.activeItem.h_hip} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="armscye" class="form-label">armscye</label>
                                    <input type="number" name='armscye' value={this.state.activeItem.armscye} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_bicept" class="form-label">h_bicept</label>
                                    <input type="number" name='h_bicept' value={this.state.activeItem.h_bicept} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_elbow" class="form-label">h_elbow</label>
                                    <input type="number" name='h_elbow' value={this.state.activeItem.h_elbow} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_wrist" class="form-label">h_wrist</label>
                                    <input type="number" name='h_wrist' value={this.state.activeItem.h_wrist} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_thigh" class="form-label">h_thigh</label>
                                    <input type="number" name='h_thigh' value={this.state.activeItem.h_thigh} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_knee" class="form-label">h_knee</label>
                                    <input type="number" name='h_knee' value={this.state.activeItem.h_knee} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="h_anckle" class="form-label">h_anckle</label>
                                    <input type="number" name='h_anckle' value={this.state.activeItem.h_anckle} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_upper_chest" class="form-label">l_upper_chest</label>
                                    <input type="number" name='l_upper_chest' value={this.state.activeItem.l_upper_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_chest" class="form-label">l_chest</label>
                                    <input type="number" name='l_chest' value={this.state.activeItem.l_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_lower_chest" class="form-label">l_lower_chest</label>
                                    <input type="number" name='l_lower_chest' value={this.state.activeItem.l_lower_chest} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_stomach" class="form-label">l_stomach</label>
                                    <input type="number" name='l_stomach' value={this.state.activeItem.l_stomach} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_waist" class="form-label">l_waist</label>
                                    <input type="number" name='l_waist' value={this.state.activeItem.l_waist} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_hip" class="form-label">l_hip</label>
                                    <input type="number" name='l_hip' value={this.state.activeItem.l_hip} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_bicept" class="form-label">l_bicept</label>
                                    <input type="number" name='l_bicept' value={this.state.activeItem.l_bicept} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_elbow" class="form-label">l_elbow</label>
                                    <input type="number" name='l_elbow' value={this.state.activeItem.l_elbow} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_wrist" class="form-label">l_wrist</label>
                                    <input type="number" name='l_wrist' value={this.state.activeItem.l_wrist} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_thigh" class="form-label">l_thigh</label>
                                    <input type="number" name='l_thigh' value={this.state.activeItem.l_thigh} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_knee" class="form-label">l_knee</label>
                                    <input type="number" name='l_knee' value={this.state.activeItem.l_knee} onChange={this.handleChange} class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="l_anckle" class="form-label">l_anckle</label>
                                    <input type="number" name='l_anckle' value={this.state.activeItem.l_anckle} onChange={this.handleChange} class="form-control" />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeEditModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Measurement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure, you want to delete measurement?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeDeleteModal}>
                            Close
                        </Button>
                        <a onClick={() => this.deleteItem(this.state.activeItem)} className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Delete</a>
                    </Modal.Footer>
                </Modal>

                {/* ========= CLIENT PROFILE PDF ========= */}
                <div class="d-none">
                    <div id="meu-template" className="meu-template">
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={logo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-responsive table-size">
                                <h5 class="mt-40 ml-70">1. Dados pessoais/Personal details</h5>
                                <table class="table table-bordered data">
                                    <tbody>
                                        <tr>
                                            <td>Nome próprio/ First Name (s): </td>
                                            <td>{this.state.client.first_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Apelido/Surname: </td>
                                            <td>{this.state.client.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td>{this.state.client.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Telemóvel/Telephone:</td>
                                            <td>{this.state.client.phone}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h5 class="ml-70">2. Medidas</h5>
                                <table class="table data">
                                    <tbody>
                                        <tr>
                                            <td>yoke:</td>
                                            <td>{this.state.activeItem.yoke}</td>
                                            <td>h_lower_chest:</td>
                                            <td>{this.state.activeItem.h_lower_chest}</td>
                                            <td>h_bicept</td>
                                            <td>{this.state.activeItem.h_bicept}</td>
                                        </tr>
                                        <tr>
                                            <td>back:</td>
                                            <td>{this.state.activeItem.back}</td>
                                            <td>h_stomach:</td>
                                            <td>{this.state.activeItem.h_stomach}</td>
                                            <td>h_elbow</td>
                                            <td>{this.state.activeItem.h_elbow}</td>
                                        </tr>
                                        <tr>
                                            <td>shoulder:</td>
                                            <td>{this.state.activeItem.shoulder}</td>
                                            <td>h_waist</td>
                                            <td>{this.state.activeItem.h_waist}</td>
                                            <td>h_wrist</td>
                                            <td>{this.state.activeItem.h_wrist}</td>
                                        </tr>
                                        <tr>
                                            <td>h_upper_chest:</td>
                                            <td>{this.state.activeItem.h_upper_chest}</td>
                                            <td>h_hip:</td>
                                            <td>{this.state.activeItem.h_hip}</td>
                                            <td>h_thigh</td>
                                            <td>{this.state.activeItem.h_thigh}</td>
                                        </tr>
                                        <tr>
                                            <td>h_chest:</td>
                                            <td>{this.state.activeItem.h_chest}</td>
                                            <td>armscye</td>
                                            <td>{this.state.activeItem.armscye}</td>
                                            <td>h_knee</td>
                                            <td>{this.state.activeItem.h_knee}</td>
                                        </tr>
                                        <tr>
                                            <td>h_anckle:</td>
                                            <td>{this.state.activeItem.h_anckle}</td>
                                            <td>l_upper_chest</td>
                                            <td>{this.state.activeItem.l_upper_chest}</td>
                                            <td>l_chest</td>
                                            <td>{this.state.activeItem.l_chest}</td>
                                        </tr>
                                        <tr>
                                            <td>l_lower_chest:</td>
                                            <td>{this.state.activeItem.l_lower_chest}</td>
                                            <td>l_stomach</td>
                                            <td>{this.state.activeItem.l_stomach}</td>
                                            <td>l_waist</td>
                                            <td>{this.state.activeItem.l_waist}</td>
                                        </tr>
                                        <tr>
                                            <td>l_hip:</td>
                                            <td>{this.state.activeItem.l_hip}</td>
                                            <td>l_bicept</td>
                                            <td>{this.state.activeItem.l_bicept}</td>
                                            <td>l_elbow</td>
                                            <td>{this.state.activeItem.l_elbow}</td>
                                        </tr>
                                        <tr>
                                            <td>l_wrist:</td>
                                            <td>{this.state.activeItem.l_wrist}</td>
                                            <td>l_thigh</td>
                                            <td>{this.state.activeItem.l_thigh}</td>
                                            <td>l_knee</td>
                                            <td>{this.state.activeItem.l_knee}</td>
                                        </tr>
                                        <tr>
                                            <td>l_anckle:</td>
                                            <td>{this.state.activeItem.l_anckle}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div >
            </>
        )
    }
}