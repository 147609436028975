import React from "react";
import styles from '../../../assets/styles/css/sb-admin-2.module.css'
import '../../../assets/styles/css/sb-admin-2.min.css'
import skyJacketLogo from '../../../assets/images/logos/sj_l.png'
import html2pdf from 'html2pdf.js';

export default class Profile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            users: [],
            selected_user: [],
            counter: 0,
        }

        this.downloadProfilePDF = this.downloadProfilePDF.bind(this)
    }

    componentWillMount() {
        this.fetchUser()
    }

    forceUpdate = () => {
        this.setState({ counter: this.state.counter + 1 });
    };

    fetchUser() {
        fetch('https://skyjacket.co.za/api/users-list/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    users: data
                })
            )
    }

    downloadProfilePDF = (e) => {

        var { name, value } = e.target
        var users_list = this.state.users
        var user = users_list.find(function (users_list) {
            return users_list.id == value
        })
        this.state.selected_user = user
        this.forceUpdate()

        const inputElement = document.getElementById('meu-template');
        const options = {
            filename: 'profile.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
        html2pdf(inputElement, options);
    }

    render() {

        var self = this
        var users = self.state.users

        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">Profile</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Profile</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="role" class="form-label">Staff</label>
                                            <select name="user" onChange={self.downloadProfilePDF} class={`${styles.formControl} mb-3`}
                                                aria-label="Default select example" id="role">
                                                <option disabled selected>Select the staff role</option>
                                                {users.map(function (user, index) {
                                                    return (
                                                        <option value={user.id}>{user.username}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                {/* ====== PROFILE PDF TEMPLATE ========== */}
                <div class="d-none">
                    <div id="meu-template" className="meu-template">
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={skyJacketLogo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name" style={{ fontSize: '23pt' }}>SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="table-responsive table-size">
                                <h5 class="mt-40 ml-70">1. Dados pessoais/Personal details</h5>
                                <table class="table table-bordered data">
                                    <tbody>
                                        <tr>
                                            <td>Nome próprio/ First Name (s): </td>
                                            <td>{this.state.selected_user.first_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Apelido/Surname: </td>
                                            <td>{this.state.selected_user.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Endereço/postal Address:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Endereço electrónico/ E-mail address: </td>
                                            <td>{this.state.selected_user.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Telemóvel/Telephone:</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h5 class="ml-70">2. Dados do Colaborador na Sky Jacket</h5>
                                <table class="table data">
                                    <tbody>
                                        <tr>
                                            <td>Data de ínicio:</td>
                                            <td></td>
                                            <td>Data de termíno:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>Cargo:</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>Descrição do cargo:</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Carga horária:</td>
                                            <td></td>
                                            <td>Salário:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>Observações:</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table class="table data">
                                    <tbody>
                                        <tr>
                                            <td>Data de ínicio:</td>
                                            <td></td>
                                            <td>Data de termíno:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>Cargo:</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>Descrição do cargo:</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Carga horária:</td>
                                            <td></td>
                                            <td>Salário:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>Observações:</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table data">
                                    <tbody>
                                        <tr>
                                            <td>Data de ínicio:</td>
                                            <td></td>
                                            <td>Data de termíno:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>Cargo:</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>Descrição do cargo:</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Carga horária:</td>
                                            <td></td>
                                            <td>Salário:</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>Observações:</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p class="page">pagina 1</p>
                                <h5 class="mt-60 ml-70">3. Idiomas/ Languages</h5>
                                <table class="table data">
                                    <thead>
                                        <th>Idioma</th>
                                        <th>Fala</th>
                                        <th>Leitura</th>
                                        <th>Escrita</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h5 class="ml-70">4. Projectos realizados/Projects carried out</h5>
                                <table class="table data">
                                    <tbody>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h5 class="ml-70">5. Aspirações da carreira/ Career aspirations</h5>
                                <table class="table data">
                                    <tbody>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h5 class="ml-70">6. Outras actividades/Other activities</h5>
                                <table class="table data">
                                    <tbody>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h5 class="ml-70">7. Avalição de Perfil/Profile assessment</h5>
                                <table class="table data">
                                    <tbody>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>-</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}