import './App.css';
import TemplateAdmin from './pages/admin/TemplateAdmin';
import Template from './pages/visitor/TemplateVisitor';

import React, { useState } from 'react';
import { CartProvider } from './components/CartContext'; // Adjust the path as necessary
import './assets/styles/custom/cartStyle'; // Assuming you have a CSS file for styling

const App = () => {
	var url = window.location.href
	var route = url.split('/')

	return (
		<CartProvider>
			<div className="app">
				{/* Your other components */}
			</div>
			{route[3] === 'administration' ? <TemplateAdmin /> : <Template />}
			{/* route[3] === 'sales' ? <TemplateSales /> : <Template /> */}
			{/* route[3] === 'marketing' ? <TemplateMarketing /> : <Template /> */}
			{/* route[3] === 'it' ? <TemplateIT /> : <Template /> */}
			{/* route[3] === 'tailor' ? <TemplateTailor/> : <Template /> */}
		</CartProvider>
	);
};

export default App;
