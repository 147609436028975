import React from "react";
import styles from '../../../assets/styles/css/sb-admin-2.module.css'
import '../../../assets/styles/css/sb-admin-2.min.css'
import skyJacketLogo from '../../../assets/images/logos/sj_l.png'
import { Modal, Button } from 'react-bootstrap'
import jsPDFInvoiceTemplate, { OutputType, jsPDF } from "jspdf-invoice-template"
import html2pdf from 'html2pdf.js';

export default class PaySlip extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            clicked_slip: [],
            users: [],
            pay_slips: [],
            staff: [],
            activeItem: {
                user: null,
                over_time: '',
                pay_cuts: '',
                reason: '',
                payment_method: '',
            },
            showPaySlipModal: false,
            counter: 0,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getCookie = this.getCookie.bind(this)
    }

    forceUpdate = () => {
        this.setState({ counter: this.state.counter + 1 });
    };

    componentWillMount() {
        this.fetchUser()
        this.fetchPaySlip()
    }

    getNextMonth() {
        const date = new Date()
        return (date.getMonth() + 1)
    }

    openPaySlipModal = (e) => {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.details,
                [name]: value,
            },
            showPaySlipModal: true
        });
    };

    closePaySlipModal = () => {
        this.setState({ showPaySlipModal: false });
    };

    handleChange(e) {
        var { name, value } = e.target

        this.setState({
            activeItem: {
                ...this.state.activeItem,
                [name]: value,
            }
        })

    }

    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    downloadPDF(pay_slip) {

        const dataAtual = new Date();
        const ano = dataAtual.getFullYear();
        const mes = dataAtual.getMonth() + 1;
        const dia = dataAtual.getDate();

        this.state.clicked_slip = pay_slip
        this.forceUpdate()

        this.handleDownloadPDF()

        // const pdfObject = jsPDFInvoiceTemplate(props)
    }

    handleDownloadPDF = () => {
        const inputElement = document.getElementById('meu-template');
        html2pdf(inputElement);
    };

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state.activeItem);
        var csrftoken = this.getCookie('csrftoken');

        var url = 'https://skyjacket.co.za/api/post-payslip/';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify(this.state.activeItem)
        }).then((response) => {
            this.fetchPaySlip()
            this.setState({
                activeItem: {
                    user: null,
                    over_time: '',
                    pay_cuts: '',
                    reason: '',
                    payment_method: '',
                }
            })
        }).catch(function (error) {
            console.log('ERROR', error);
        })

        this.closePaySlipModal()

    }

    fetchUser() {
        fetch('https://skyjacket.co.za/api/users-list/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    users: data
                })
            )
    }

    fetchPaySlip() {
        fetch('https://skyjacket.co.za/api/get-payslip/')
            .then(response => response.json())
            .then(data =>
                this.setState({
                    pay_slips: data
                })
            )
    }

    render() {

        var self = this
        var users = self.state.users
        var pay_slips = self.state.pay_slips
        const dataAtual = new Date();
        const ano = dataAtual.getFullYear();
        const mes = dataAtual.getMonth() + 1;
        const dia = dataAtual.getDate();
        var pay_slip = this.state.clicked_slip

        return (
            <>
                <div class="container-fluid">
                    <p>
                        <a href="/administration/dashboard" style={{ color: 'grey' }}>/dashboard</a>
                    </p>
                    <h1 class="h3 mb-2 text-gray-800">PaySlip</h1>
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">PaySlip</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <form id='form' onSubmit={this.handleSubmit}>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <label for="role" class="form-label">Staff</label>
                                            <select name="user" onChange={self.openPaySlipModal} class={`${styles.formControl} mb-3`}
                                                aria-label="Default select example" id="role">
                                                <option disabled selected>Select the staff role</option>
                                                {users.map(function (user, index) {
                                                    return (
                                                        <option value={user.id}>{user.username}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="container-fluid">
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">list of roles</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table className={`${styles.table} ${styles.tableBordered} table-striped dataTable dtr-inline collapsed`} id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>user</th>
                                            <th>Over time</th>
                                            <th>Pay cuts</th>
                                            <th>Reason</th>
                                            <th>Payment method</th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>user</th>
                                            <th>description</th>
                                            <th>Pay cuts</th>
                                            <th>Reason</th>
                                            <th>Payment method</th>
                                            <th>actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {pay_slips.map(function (pay_slip, index) {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{pay_slip.username}</td>
                                                    <td>{pay_slip.over_time}</td>
                                                    <td>{pay_slip.pay_cuts}</td>
                                                    <td>{pay_slip.reason}</td>
                                                    <td>{pay_slip.payment_method}</td>
                                                    <td>
                                                        <div class="actions-container flex">
                                                            <a style={{ cursor: 'pointer', marginRight: '10px', color: 'grey' }} onClick={() => self.downloadPDF(pay_slip)} class="custom-action-button">
                                                                <i class="fa fa-download"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

                <Modal show={this.state.showPaySlipModal} onHide={this.closePaySlipModal}>
                    <form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>next payment date: 25/{this.getNextMonth()}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Over time</label>
                                    <input type="text" name='over_time' value={this.state.activeItem.over_time} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Pay cuts</label>
                                    <input type="text" name='pay_cuts' value={this.state.activeItem.pay_cuts} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Reason</label>
                                    <input type="text" name='reason' value={this.state.activeItem.reason} onChange={this.handleChange} class="form-control" required />
                                </div>
                                <div class="col-sm-6">
                                    <label for="task" class="form-label">Payment method</label>
                                    <input type="text" name='payment_method' value={this.state.activeItem.payment_method} onChange={this.handleChange} class="form-control" required />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closePaySlipModal}>
                                Close
                            </Button>
                            <button type="submit" name='Add' className={`btn ${styles.btnPrimary}`} style={{ color: 'white' }}>Save Changes</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* ====== PDF TEMPLATE ======== */}
                <div class="d-none">
                    <div id="meu-template" className="meu-template">
                        <div class="d-flex">
                            <div class="logo-content d-inline">
                                <img class="logo-pdf" src={skyJacketLogo} />
                            </div>
                            <div class="d-inline sky-fig">
                                <div class="trapezio"></div>
                                <div class="quadrado">
                                    <h2 class="compony-name">SKY JACKET</h2>
                                    <p class="compony-title" style={{ fontSize: '9pt' }}>FILL YOUR SPACE</p>
                                </div>
                            </div>
                            <div class="d-inline">
                                <div class="retangulo"></div>
                                <div class="info">
                                    <p style={{ fontSize: '10pt' }}>SKY JACKET, SOC UNI, LDA</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>PHONE: +258 (21) 089 045</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>EMAIL: INFO@SKYJACKET.CO</p>
                                    <p class="info-text" style={{ fontSize: '10pt' }}>WEB: WWW.SKYJACKET.CO</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <table class="table date">
                                <tbody>
                                    <td>Date</td>
                                    <td>{dia + "/" + mes + "/" + ano}</td>
                                </tbody>
                            </table>
                            <div class="table-responsive table-size">
                                <table class="table dataTable data">
                                    <tbody>
                                        <tr>
                                            <td>Employee Name:</td>
                                            <td>{pay_slip.first_name+' '+pay_slip.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Department:</td>
                                            <td>IT</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style={{ textAlign: 'center', background: '#160f42', color: 'white' }}>Payment Information</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table class="table dataTable data">
                                    <tbody>
                                        <tr style={{ background: '#160f42', color: 'white' }}>
                                            <td>Earning Amount:</td>
                                            <td>Deduction:</td>
                                        </tr>
                                        <tr>
                                            <td>Monthly Pay:</td>
                                            <td>10000MZN</td>
                                        </tr>
                                        <tr>
                                            <td>Over time:</td>
                                            <td>{pay_slip.over_time}</td>
                                        </tr>
                                        <tr>
                                            <td>Pay cuts:</td>
                                            <td>{pay_slip.pay_cuts}</td>
                                        </tr>
                                        <tr>
                                            <td>Reason:</td>
                                            <td>{pay_slip.reason}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Earnings:</td>
                                            <td>10000MZN</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table class="table dataTable data">
                                    <tbody>
                                        <tr style={{ background: '#160f42', color: 'white' }}>
                                            <td colspan="2">Form of Payment:</td>
                                        </tr>
                                        <tr>
                                            <td>Payment method:</td>
                                            <td>{pay_slip.payment_method}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}